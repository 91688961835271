// Import necessary dependencies and styles
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/createAdStyles.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';

// Define the CreateRoom component
const CreateRoom = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  // State to manage room data
  const [roomData, setRoomData] = useState({
    room_name: '',
    room_description: '',
    images: [] as File[],
  });

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Create a new FormData object to handle file uploads
      const formDataToSend = new FormData();
      formDataToSend.append('room_name', roomData.room_name);
      formDataToSend.append('room_description', roomData.room_description);
      roomData.images.forEach((image, index) => {
        formDataToSend.append(`images[${index}]`, image);
      });

      // Send a POST request to the API to add a room
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROOMS}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        // If the request is successful, navigate to the appropriate page
        navigate('/rooms');
      } else {
        // If there is an error, log it
        console.error('Error adding room');
      }
    } catch (error) {
      // If there is a general error, log it
      console.error('API request failed', error);
    }
  };

  // Handle image file change
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    setRoomData({ ...roomData, images: files });
  };

  return (
    <div className="add-role-container create-ad-container">
      <h2>Create Room</h2>
      <Link to="/list-rooms" className="role-back-button">
        Saved Rooms <i className="fas fa-arrow-right"></i>
      </Link>
      <form className="add-role-form create-ad-form" onSubmit={handleSubmit}>
        <div className="create-add-form-column create-add-form-column-full-width">
          <label className="create-ad-label" htmlFor="roomName">
            Room Name:
          </label>
          <input
            type="text"
            id="roomName"
            className="create-ad-input"
            placeholder="Enter room name"
            value={roomData.room_name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setRoomData({ ...roomData, room_name: e.target.value })
            }
          />
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          <label className="create-ad-label" htmlFor="roomDescription">
            Room Description:
          </label>
          <textarea
            id="roomDescription"
            className="create-ad-input"
            placeholder="Enter room description"
            value={roomData.room_description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setRoomData({ ...roomData, room_description: e.target.value })
            }
          />
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          <label className="create-ad-label" htmlFor="roomImages">
            Upload Images related to the room (you can upload multiple):
          </label>
          <input
            type="file"
            id="roomImages"
            className="create-ad-input"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          {/* Display the selected images */}
          {roomData.images.length > 0 && (
            <div>
              <label className="create-ad-label">Selected Images:</label>
              {roomData.images.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)} // Use createObjectURL to display the image
                  alt={`Selected Room Image ${index + 1}`}
                  style={{ maxWidth: '100%', maxHeight: '200px' }} // Adjust the style as needed
                />
              ))}
            </div>
          )}
        </div>

        {/* Submit button */}
        <button type="submit" className="create-ad-submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateRoom;
