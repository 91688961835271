// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/dashboard.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import { FaSearch, FaPlay, FaEye, FaThumbsUp, FaThumbsDown, FaHeart } from 'react-icons/fa'; // Import additional icons
import VideoModal from './VideoModal'; // Import the VideoModal component
import CardMenu from './CardMenu'; // Import the CardMenu component
import themeBanner from '../components/publicpage/images/apriltheme.jpg'; // Import the theme banner image


// Define interfaces for Ad and AdWithDetails
interface Ad {
  id: number;
  title: string;
  description: string;
  image_url?: string | null;
  video_url?: string | null;
  category_id: number;
  subcategory_id: number;
  user_id: number;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  ad_type: string;
  price: number | null;
  default_image: { id: number; image_url: string } | null;
  likes: number;
  dislikes: number;
  views: number;
  badge: string;
}

export interface AdWithDetails extends Ad {}

// Define the DashboardContent component
const DashboardContent: React.FC = () => {
  // Create necessary state variables
  const navigate = useNavigate();
  const { user } = useAuth();
  const [ads, setAds] = useState<Ad[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAd, setSelectedAd] = useState<AdWithDetails | null>(null); // State for selected ad to display the modal

  // Fetch ads from the API
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PUBLICADS}`, {});

        if (response.ok) {
          const data = await response.json();
          setAds(data.ads);
        } else {
          console.error('Error fetching ads');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchAds();
  }, []);

  // Filter ads based on search query
  const filteredAds = ads.filter(
    (ad) =>
      ad.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ad.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate the displayed ads
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAds = filteredAds.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle change in records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Handle pagination
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle play button click
  const handlePlayButtonClick = (ad: AdWithDetails) => {
    setSelectedAd(ad);
  };

  // Handle modal close
  const closeModal = () => {
    setSelectedAd(null);
  };

  return (
    <div className="dashboard-content">
      <section className="theme-month">
        <div className="theme-content">
          <div className="theme-banner">
            <h3 className="public-title">Theme of the Month</h3>
            <img src={themeBanner} alt="Theme of the Month Banner" />
          </div>
          <div className="theme-text">
            <h3 className="slogan public-title">Happy new month</h3>
            <p className="description">We as Necla Spa & Barbershop wish all our esteemed clients a Happy New and Holistic Month. 
              Welcome to Necla Spa & Barbershop for all you Holistic Spa & Barber concerns.
            </p>
          </div>
        </div>
      </section>
      <hr></hr>
          {/* Add CardMenu component below the banner */}
          <CardMenu />

      {/* Advertisements section */}
      <h2 className='dash-title'>Our Blog</h2>
      <div className="advertisements-container">
        {currentAds.map((ad: AdWithDetails) => {
          // Fetch the default image
          const defaultImage = ad.default_image;

          return (
            <div key={ad.id} className="advertisement-card">
              <div className='advert-image-holder'>
                {defaultImage ? (
                  <img
                    src={defaultImage.image_url}
                    alt={`Ad Image ${ad.id}`}
                    className="advertisement-image"
                  />
                ) : (
                  <div className="default-image-placeholder">
                    {/* Display default image placeholder */}
                  </div>
                )}
              </div>
              <div className="advert-card-content">
                <div className='non-title-content'>
                 
                  <div className="likes-dislikes">
                    <span>
                      <FaThumbsUp />30 {ad.likes}
                    </span>
                    {/* <span>
                      <FaThumbsDown /> {ad.dislikes}
                    </span> */}
                  </div>
                  <div className="likes-dislikes">
                    {/* <span>
                      <FaThumbsUp /> {ad.likes}
                    </span> */}
                    <span>
                      <FaThumbsDown />0 {ad.dislikes}
                    </span>
                  </div>
                  <div className="views-count">
                    <span>
                      <FaEye />10 {ad.views}
                    </span>
                  </div>
                  <div className="glowing-badge">
                    <FaHeart />{ad.badge}
                  </div>
                   
                  <p className='ad-type'>{ad.ad_type}</p>
                </div>
                <div className="star-rating">
                    <p>Editor:{ad.user_full_name}</p>
                    ★★★★★
                  </div>
              </div>
              <div className='public-content-title'>
              <p>{ad.title}</p>
              </div>
              <div className="button-icons">
              <Link className="view-button" to={`/view-ad/${ad.id}`}>
                View more...
              </Link>
               
                {/* ... (existing code) ... */}
              </div>
            </div>
          );
        })}
      </div>

      {/* Render VideoModal if selectedAd is not null */}
      {selectedAd && <VideoModal ad={selectedAd} onClose={closeModal} />}

      {/* Pagination section */}
      <div className="pagination-container">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: Math.ceil(filteredAds.length / recordsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredAds.length / recordsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DashboardContent;
