import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';

interface Subcategory {
  id: number;
  category_id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

const SubCategoriesManagement: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [subcategories, setSubcategories] = useState<Subcategory[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.SUBCATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubcategories(data.subcategories);
        } else {
          console.error('Error fetching subcategories');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchSubcategories();
  }, [accessToken]);

  const handleEdit = (subcategoryId: number) => {
    console.log(`Editing subcategory with ID ${subcategoryId}`);
    navigate(`/edit-subcategory/${subcategoryId}`);
  };

  const handleDelete = async (subcategoryId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.SUBCATEGORIES}/${subcategoryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Subcategory with ID ${subcategoryId} deleted successfully`);
        setSuccessMessage(`Subcategory with ID ${subcategoryId} deleted successfully`);
        // You may want to update the state to remove the deleted subcategory without reloading the page
      } else {
        console.error(`Error deleting subcategory with ID ${subcategoryId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const filteredSubcategories = subcategories.filter((subcategory) =>
    subcategory.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentSubcategories = filteredSubcategories.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Subcategory Management</h2>

      <div className="role-buttons">
        <Link to="/advertisements">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </Link>
        <Link to="/add-subcategory">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search subcategories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <table className="custom-table">
        <thead>
          <tr>
            <th>Subcategory</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentSubcategories.map((subcategory) => (
            <tr key={subcategory.id}>
              <td>{subcategory.name}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(subcategory.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(subcategory.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredSubcategories.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default SubCategoriesManagement;
