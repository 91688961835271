// EditUserAccountDashboard.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import EditUserAccounts from './UserManagement/EditUserAccounts';

const EditUserAccountDashboard: React.FC = () => {

  return (
    <div className="admin-dashboard">
      <TopNavigation />
      <Row className='wrapper'>
        <Col md={2} className="sidebar">
          <Sidebar />
        </Col>
        <Col md={10} className="main-content">
          {/* Pass accessToken as a prop to AddRole */}
          <EditUserAccounts/>
        </Col>
      </Row>
    </div>
  );
};

export default EditUserAccountDashboard;
