// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/dashboard.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
// @ts-ignore
import bioVideo from '../components/publicpage/videos/biovideo.mp4'; // Import the bio video
// @ts-ignore

import neclaLogo from '../components/publicpage/images/necla-logo.png'; // Import other resources as needed

// Define the AboutContent component
const AboutContent: React.FC = () => {
  return (
    <div className="dashboard-content">
      
      <section className="bio-section">
      <div className="bio-content">
        <div className="text-description">
          <h3 className="public-title">About Necla Spa & Barber</h3>
          <p>                 
            As you step into the necla spa, you will enter an oasis of peace, calm and tranquility.
            Whether you are escaping the pressure of everyday life or looking to a heightened relaxing experience, you will enter a world where time stands still Wellness and relaxation pervades.
            You will be in the hands of our friendly, highly qualified team of therapists where you choose your treatment.
            Whatever your goals, your experience awaits!
          </p>
          {/* Add the "Know More About Us" button */}
      
        </div>
        <div className="video-banner">
          <video width="100%" height="100%" controls autoPlay loop muted>
            <source src={bioVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
      <hr></hr>
      <footer>
        <div className="footer-content">
          {/* Logo */}
          <div className="footer-logo">
            <img src={neclaLogo} alt="Necla Logo" />
          </div>
          
          {/* Social Media Icons */}
          <div className="footer-social">
            <a href="https://web.facebook.com/NeclaBeautyandSpa/?_rdc=1&_rdr"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/neclaspabarber"><i className="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/explore/locations/114366393540115/necla-spa-barbershop/"><i className="fab fa-instagram"></i></a>
            {/* <a href="#"><i className="fab fa-linkedin-in"></i></a> */}
          </div>
          
          {/* Contact Us Details */}
          <div className="footer-contact">
            <p>info@neclaspa.co.ke</p>
            <p>+254 721 313219</p>
            <p>Northpark Hub Mall 3rd floor Suit-T2, Ruiru Bypass, Kamakis Road, Nairobi, Kenya</p>
        </div>
        
          
          {/* Copyright Protection */}
          <div className="footer-copyright">
            <p>&copy; <span id="currentYear"></span> Necla Spa & Barber. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutContent;
