// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/dashboard.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import { FaSearch, FaPlay, FaEye, FaThumbsUp, FaThumbsDown, FaHeart } from 'react-icons/fa'; // Import additional icons
import VideoModal from './VideoModal'; // Import the VideoModal component
import CardMenu from './CardMenu'; // Import the CardMenu component
import BookingFrequencyChart from './BookingFrequencyChart'; // Import the CardMenu component


import image1 from '../assets/images/neclaposter.png';
import image2 from '../assets/images/nature.jpg';
// Import other images as needed

// Define interfaces for Ad and AdWithDetails
interface Ad {
  id: number;
  title: string;
  description: string;
  image_url?: string | null;
  video_url?: string | null;
  category_id: number;
  subcategory_id: number;
  user_id: number;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  ad_type: string;
  price: number | null;
  default_image: { id: number; image_url: string } | null;
  likes: number;
  dislikes: number;
  views: number;
  badge: string;
}

export interface AdWithDetails extends Ad {}

// Define the DashboardContent component
const DashboardContent: React.FC = () => {
  // Create necessary state variables
  const navigate = useNavigate();
  const { user } = useAuth();
  const [ads, setAds] = useState<Ad[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAd, setSelectedAd] = useState<AdWithDetails | null>(null); // State for selected ad to display the modal

  // Fetch ads from the API
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PUBLICADS}`, {});

        if (response.ok) {
          const data = await response.json();
          setAds(data.ads);
        } else {
          console.error('Error fetching ads');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchAds();
  }, []);

  // Filter ads based on search query
  const filteredAds = ads.filter(
    (ad) =>
      ad.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ad.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate the displayed ads
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAds = filteredAds.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle change in records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Handle pagination
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle play button click
  const handlePlayButtonClick = (ad: AdWithDetails) => {
    setSelectedAd(ad);
  };

  // Handle modal close
  const closeModal = () => {
    setSelectedAd(null);
  };
  return (
    <div className="dashboard-content">
          {/* Add CardMenu component below the banner */}
          <CardMenu />
    </div>
  );
};

export default DashboardContent;
