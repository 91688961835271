// AdsManagementDashboard.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import AdsManagement from './AdsManagement/AdsManagement';
import { useAuth } from '../AuthContext'; // Import useAuth

const AdsManagementDashboard: React.FC = () => {

  return (
    <div className="admin-dashboard">
      {/* Pass the user prop to TopNavigation */}
      <TopNavigation />
      <Row className='wrapper'>
        <Col md={2} className="sidebar">
          <Sidebar />
        </Col>
        <Col md={10} className="main-content">
          {/* Pass the user prop to UserManagement */}
          <AdsManagement />
        </Col>
      </Row>
    </div>
  );
};

export default AdsManagementDashboard;
