// VideoModal.tsx

import React from 'react';
import { AdWithDetails } from './DashboardContent'; // Adjust the path based on your project structure
import '../assets/css/VideoModal.css'; // Import your custom CSS for styling

interface VideoModalProps {
  ad: AdWithDetails;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ ad, onClose }) => {
  return (
    <div className="video-modal-overlay" onClick={onClose}>
      <div className="video-modal" onClick={(e) => e.stopPropagation()}>
        <div className="video-modal-header">
          <h2>{ad.title}</h2>
        </div>
      

        <div className="video-modal-content">
          <div className="video-section">
            {/* Embed the video using ad.video_url */}
            {ad.video_url && (
              <iframe
                title={`Video for ${ad.title}`}
                width="100%"
                height="315"
                src={ad.video_url ? `https://www.youtube.com/embed/${getYouTubeVideoId(ad.video_url)}` : ''}
                frameBorder="0"
                allowFullScreen
              />
            )}
          </div>

          <div className="user-profile-section">
            <img src="path/to/user-icon.png" alt={`Profile of ${ad.user_full_name}`} />
            <div className="user-details">
            <p>{ad.user_full_name}</p>
            {/* Add rating stars here */}
            </div>
            <div className="explore-buttons">
            <button>Explore More</button>
            {/* Add more explore buttons if needed */}
            </div>
            </div>
        </div>
        <div className="video-modal-description">
          <p>{ad.description}</p>
          {/* Add comment and reply section here */}
        </div>
        <div className="video-modal-footer">
          <div className="like-dislike-section">
            {/* Like button */}
            <button className="like-button">👍 Like</button>
            {/* Dislike button */}
            <button className="dislike-button">👎 Dislike</button>
          </div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

// Function to extract YouTube video ID from URL
function getYouTubeVideoId(url: string): string | null {
  const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:.*?\/(?:v\/|e\/|u\/\w+\/|embed\/|v=)|.*?[?&]v=))([^"&?\/\s]{11})/);
  return match ? match[1] : null;
}

export default VideoModal;
