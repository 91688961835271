// UserManagement.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import '../../assets/css/UserManagement.css';

const UserManagement: React.FC = () => {
  return (
    <div className="user-management">
      <h2>Advertisement(s) Management</h2>
      <Row className="user-management-row">
        <Col md={4} sm={6} xs={12}>
          <Link to="/ad-categories">
            <Card className="user-management-card h-100">
              <Card.Body>
                <Card.Title>Advert Categories</Card.Title>
                {/* <p>Categorising Ads</p> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Link to="/sub-categories">
            <Card className="user-management-card h-100">
              <Card.Body>
                <Card.Title>Advert Types</Card.Title>
                {/* <p>Categorising Ads with types</p> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Link to="/ads">
            <Card className="user-management-card h-100">
              <Card.Body>
                <Card.Title>Advert posting</Card.Title>
                {/* <p>Creating and managing ads to post</p> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Link to="/user-management/customers">
            <Card className="user-management-card h-100">
              <Card.Body>
                <Card.Title>Advert tagging</Card.Title>
                {/* <p>Add tagging to improve add impressions and lookup</p> */}
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default UserManagement;
