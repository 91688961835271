// TopNavigation.tsx
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaBell, FaSearch, FaBars, FaShoppingCart } from 'react-icons/fa';
import logo from '../assets/images/logo.png';
import UserProfileSidebar from './UserProfileSidebar';
import { useAuth } from '../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import MobileSidebar from './MobileSidebar';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig'; // Import API configurations
import axios from 'axios'; // Import axios for making HTTP requests

const TopNavigation: React.FC = () => {
  const [isProfileSidebarOpen, setIsProfileSidebarOpen] = useState(false);
  const { user } = useAuth();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [logoClass, setLogoClass] = useState('dash-logo');
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');
  const [userDetails, setUserDetails] = useState<any | null>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isHovered) {
        setLogoClass((prevClass) =>
          prevClass === 'dash-logo' ? 'dash-logo flipped' : 'dash-logo'
        );
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isHovered]);

  const openProfileSidebar = () => {
    setIsProfileSidebarOpen(true);
  };

  const closeProfileSidebar = () => {
    setIsProfileSidebarOpen(false);
  };

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const closeMobileSidebar = () => {
    setIsMobileSidebarOpen(false);
  };

  const handleCartClick = () => {
    navigate('/cart');
  };

  
  // Fetch user details on component mount and when user or accessToken changes
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.data && response.data.user) {
          setUserDetails(response.data.user);
        } else {
          console.error('Invalid user data in the server response');
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    if (user) {
      fetchUserDetails();
    }
  }, [user, accessToken]);


  return (
    <div>
      <Navbar bg="dark" expand="lg" className="top-navigation">
        <Link to="/">
          <img
            src={logo}
            alt="Logo"
            className={logoClass}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Link>

        {/* <Button variant="outline-primary" className="menu-toggle-icon" onClick={toggleMobileSidebar}>
          Spa & Barber Services
        </Button> */}
        

        <Nav className="ml-2 brand-and-icons">
          {user ? (
            <>

          {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
            <>
                               <Nav.Link href="#" onClick={handleCartClick}>
                  <FaShoppingCart size={20} />
                  {/* {cartCount > 0 && <sup>{cartCount}</sup>} */}
                </Nav.Link>
            </>
          ) : (
            <>
              {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
                <>
                </>
              )}
            </>
          )}

              <Nav.Link href="" onClick={openProfileSidebar}>
                <FaUser size={20} />
              </Nav.Link>
              {/* <Nav.Link href="#messages">
                <FaEnvelope size={20} />
              </Nav.Link>
              <Nav.Link href="#notifications">
                <FaBell size={20} />
              </Nav.Link> */}
              <Nav.Link className="top-header-search-btn" onClick={toggleMobileSidebar}>
                <FaSearch />
              </Nav.Link>
              <Nav.Link href="" className="menu-toggle-icon-small" onClick={toggleMobileSidebar}>
                <FaBars size={20} />
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link className="top-header-search-btn" onClick={toggleMobileSidebar}>
                <FaSearch />
              </Nav.Link>
              <Nav.Link href="#messages" className="menu-toggle-icon-small" onClick={toggleMobileSidebar}>
                <FaBars size={20} />
              </Nav.Link>
              <Link to="/login" className="create-advert-button">
                Sign In
              </Link>
            </>
          )}
        </Nav>
      </Navbar>

      {isProfileSidebarOpen && <UserProfileSidebar onClose={closeProfileSidebar} />}
      {isMobileSidebarOpen && <MobileSidebar onClose={closeMobileSidebar} />}
    </div>
  );
};

export default TopNavigation;
