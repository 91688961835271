import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import '../../assets/css/ViewAdDetails.css';
import profilePictureSrc from '../../assets/images/user.png';
import whatsappImage from '../../assets/images/whatsapp.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import YouTube from 'react-youtube';
import { FaWhatsapp } from 'react-icons/fa';


// Define the Comment interface
interface Comment {
    id: number;
    user_name: string;
    comment_text: string;
  }
// Define the YouTubeOptions type
type YouTubeOptions = {
    width?: string | number;
    height?: string | number;
    videoId?: string;
    playerVars?: {
      [key: string]: any;
    };
    [key: string]: any;
  };

// Define interfaces for the Image and AdDetails objects
interface Image {
  id: number;
  image_url: string;
}

interface AdDetails {
    id: number;
    title: string;
    description: string;
    video_url: string;
    category_name: string;
    subcategory_name: string;
    user_full_name: string;
    ad_type: string;
    price: string;
    is_active: number;
    images: Image[];
    phone: string; // Add the phone property
  }
  

// Define the functional component ViewAdDetails
const ViewAdDetails: React.FC = () => {
  // Extract the "id" parameter from the URL using useParams hook
  const { id } = useParams<{ id: string }>();

  // State to hold the details of the ad, initialized as null
  const [adDetails, setAdDetails] = useState<AdDetails | null>(null);

  // Use the useAuth hook to get user information from AuthContext
  const { user } = useAuth();

  // Retrieve the access token from local storage
  const accessToken = localStorage.getItem('accessToken');

  // State to hold the comments for the ad, initialized as an empty array
const [comments, setComments] = useState<Comment[]>([]);

// State to hold the new comment being typed by the user
const [newComment, setNewComment] = useState<string>('');

// Sample comments for testing
const sampleComments: Comment[] = [
    { id: 1, user_name: 'Mitchell', comment_text: 'This is a great ad!' },
    { id: 2, user_name: 'Mike', comment_text: 'Love the video content.' },
    // Add more sample comments as needed
  ];

  // useEffect hook to fetch ad details when the component mounts or "id" or "accessToken" changes
  useEffect(() => {
    const fetchAdDetails = async () => {
      try {
        // Fetch ad details from the API using the provided endpoint and ad ID
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Include access token in headers
          },
        });

        // Check if the response is successful (HTTP status code 200)
        if (response.ok) {
          const data = await response.json();
          // Set the retrieved ad details in the component state
          setAdDetails(data.ad);
        } else {
          console.error('Error fetching ad details');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    

// Function to fetch comments for the ad
const fetchComments = async () => {
    try {
      // Fetch comments from the API using the provided endpoint and ad ID
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${id}/comments`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
      });
  
      // Check if the response is successful (HTTP status code 200)
      if (response.ok) {
        const data = await response.json();
        // Set the retrieved comments in the component state
        setComments(data.comments);
      } else {
        console.error('Error fetching comments');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };
  
  // Function to handle comment submission
  const submitComment = async () => {
    try {
      // Submit a new comment to the API
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${id}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
        body: JSON.stringify({ comment_text: newComment }),
      });
  
      // Check if the response is successful (HTTP status code 201 for creation)
      if (response.status === 201) {
        // Fetch updated comments after submission
        fetchComments();
        // Clear the newComment state
        setNewComment('');
      } else {
        console.error('Error submitting comment');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };
  

    // Call the fetchAdDetails function
    fetchAdDetails();
    fetchComments();
  }, [id, accessToken]); // Dependencies for useEffect

  // If adDetails is null, display a loading message
  if (!adDetails) {
    return <div>Loading...</div>;
  }

  // Function to extract YouTube video ID from URL
function getYouTubeVideoId(url: string): string | null {
    const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:.*?\/(?:v\/|e\/|u\/\w+\/|embed\/|v=)|.*?[?&]v=))([^"&?\/\s]{11})/);
    return match ? match[1] : null;
  }
  
    function submitComment(): void {
        throw new Error('Function not implemented.');
    }

  // Render the ad details UI
  return (
    <div className="view-ad-container">
      <div className="column video-column">
        {/* Video */}
        <div className="video-container">
           
          {/* Use iframe to embed the video, set the source from adDetails.video_url */}
          <iframe
            title="Ad Video"
            width="100%"
            height="400"
            // src={adDetails.video_url}
            src={adDetails.video_url ? `https://www.youtube.com/embed/${getYouTubeVideoId(adDetails.video_url)}` : ''}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* Images */}
        <h5 className='images-title'>Featured Posters</h5>
        <div className="images-container">
          {/* Map over adDetails.images and display images */}
          {adDetails.images.map((image) => (
            <img key={image.id} src={image.image_url} alt={`Ad Image ${image.id}`} />
          ))}
        </div>
      </div>

      <div className="column">
        {/* Description */}
        <div className="description-container">
          {/* Display ad title and description */}
          <h2>{adDetails.title}</h2>
          <div
            className="description-content"
            dangerouslySetInnerHTML={{ __html: adDetails.description }}
          />
        </div>

        {/* Social Buttons (Like and Share) */}
      <div className="social-buttons-container">
        <button className="like-button">
          <i className="fas fa-thumbs-up"></i> Like
        </button>
        <button className="share-button">
          <i className="fas fa-share"></i> Share
        </button>
      </div>

        {/* Owner Profile */}
        <div className="profile-container">
          <h3>Editor Profile</h3>
          {/* Display owner profile information */}
          <table>
            <thead>
                <th>Pic</th>
                <th>Writer</th>
                <th>Instagram</th>
                <th>Facebook</th>                {/* <th>LinkedIn</th> */}
            </thead>
            <tbody>
                <tr>
                    <td> <img src={profilePictureSrc} alt="Profile" className="sidebar-profile-picture" /></td>
                    <td>{adDetails.user_full_name}</td>
                    <td><FontAwesomeIcon icon={faInstagram} /></td>
                    <td><FontAwesomeIcon icon={faFacebook} /></td>
                </tr>
            </tbody>
          </table>
      
       {/* Comment Reply Section */}
       <div className="comment-reply-container">
        <h3>Comments</h3>
        {/* Display sample comments */}
        {sampleComments.map((comment) => (
          <div key={comment.id} className="comment">
            <strong>{comment.user_name}</strong>: {comment.comment_text}
          </div>
        ))}

        {/* Add a form for users to submit new comments */}
        <form className='comment-reply-form'>
          <textarea
            placeholder="Write a comment..."
            // value={newComment}
            // onChange={(e) => setNewComment(e.target.value)}
            className='comment-reply-form-text'
          />
          <button className="comment-button" type="button" onClick={submitComment}>
            Submit
          </button>
        </form>
      </div>
            <div className="whatsapp-icon">
            <a href={`https://wa.me/${adDetails.phone}`} target="_blank">
            <img src={whatsappImage} alt="whatsapp" />
            </a>
            </div>

        </div>  

      </div>
    </div>
  );
};

// Export the ViewAdDetails component as the default export
export default ViewAdDetails;
