import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Image, Alert } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import '../assets/schedule.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import TimePicker from 'react-time-picker';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import defaultProfileIcon from '../assets/images/user.png'; // Import facial men image
// @ts-ignore
import bioVideo from '../components/publicpage/videos/biovideo.mp4'; // Import the bio video
import { Spinner } from 'react-bootstrap';

// Define interfaces for data types
interface SpaServiceResponse {
  id: number;
  service_name: string;
  service_category: string;
  service_description: string;
  service_price: string;
  service_duration: number;
  images: Image[];
}

interface Image {
  id: number;
  image_url: string;
}

interface User {
  name: string;
  id: number;
  username: string;
  profile_picture?: string; // Optional profile picture
}

interface Room {
  id: number;
  room_name: string;
  room_images: Image[];
}

const ReScheduleContent: React.FC = () => {
  const { user, logout } = useAuth();
  const accessToken = localStorage.getItem('accessToken');
  const { id } = useParams<{ id: string }>(); // Extract ID from URL params
  const navigate = useNavigate();

  const [service, setService] = useState<SpaServiceResponse | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedUserDetails, setSelectedUserDetails] = useState<User | null>(null); // Declare selectedUserDetails
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<number | null>(null);
  const [selectedRoomDetails, setSelectedRoomDetails] = useState<Room | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // State variable for loading status
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editingAppointment, setEditingAppointment] = useState<boolean>(false); // State variable for editing appointment
// Fetch the record by ID from the API when the component mounts
useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setEditingAppointment(true);
          // Populate form fields with fetched data
          setSelectedDate(new Date(data.appointment.booking_date));
          setSelectedTime(data.appointment.booking_time);
          setSelectedUser(data.appointment.therapist_id);
          setSelectedRoom(data.appointment.room_id);
          setService({
            id: data.appointment.service_id,
            service_name: data.appointment.service_name,
            service_category: '', // You might need to fetch this from the backend
            service_description: '', // You might need to fetch this from the backend
            service_price: '', // You might need to fetch this from the backend
            service_duration: data.appointment.booking_duration,
            images: [], // You might need to fetch this from the backend
          });
        } else {
          console.error('Error fetching appointment details:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('API request failed:', error);
      }
    };
  
    if (accessToken && id) {
      fetchAppointmentDetails();
    }
  }, [accessToken, id]);
  
  // Remaining event handlers and JSX code...

// Event handler for user change
const handleUserChange = (userId: number) => {
    setSelectedUser(userId);
    
    // Find the selected user details
    const user = users.find(user => user.id === userId);
    setSelectedUserDetails(user || null);
  };
  
  // Event handler for room change
  const handleRoomChange = (roomId: number) => {
    setSelectedRoom(roomId);
    
    // Find the selected room details
    const room = rooms.find(room => room.id === roomId);
    setSelectedRoomDetails(room || null);
  };
  
  // Event handler for date change
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  
  // Event handler for time change
  const handleTimeChange = (time: string | null) => {
    if (time !== null) {
      // Parse hours, minutes, and am/pm from the input fields
      const [hours, minutes, ampm] = time.split(':');
      // Convert to 24-hour format
      let formattedHours = parseInt(hours, 10);
      if (ampm === 'PM' && formattedHours < 12) {
        formattedHours += 12;
      } else if (ampm === 'AM' && formattedHours === 12) {
        formattedHours = 0;
      }
      // Format the time
      const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes}`;
      setSelectedTime(formattedTime);
    }
  };
  
  // Event handler for form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true
  
    try {
      // Check if date and time are selected
      if (!selectedDate || !selectedTime) {
        console.error('Please select date and time');
        // Update the validationErrors state
        setValidationErrors(['Please select date and time']);
        setLoading(false); // Set loading state to false
        return;
      }
  
      // Log the selected date and time before formatting
      console.log('Selected Date:', selectedDate);
      console.log('Selected Time:', selectedTime);
  
      // Format the date
      const formattedDate = selectedDate.toISOString().split('T')[0];
  
      // Construct the complete date-time string
      const dateTimeString = `${formattedDate} ${selectedTime}`;
  
      // Validate the date-time string
      const validDateTime = new Date(dateTimeString);
      if (isNaN(validDateTime.getTime())) {
        console.error('Invalid date or time format');
        // Update the validationErrors state
        setValidationErrors(['Invalid date or time format']);
        setLoading(false); // Set loading state to false
        return;
      }
  
      // If everything is valid, proceed with form submission
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
        //   cart_id: cart_id,
          room_id: selectedRoom,
          therapist_id: selectedUser,
          booking_date: formattedDate,
          booking_time: selectedTime,
          booking_duration: service?.service_duration,
          booking_status: 'pending',
        }),
      });
  
      if (response.ok) {
        console.log('Appointment created successfully');
        navigate('/appointments');
      } else {
        const responseData = await response.json();
        if (response.status === 422) {
          // Validation error
          console.error('Validation Error:', responseData.error);
          // Update the validationErrors state
          setValidationErrors(Object.values(responseData.error));
        } else {
          setShowErrorModal(true);
          console.error('Error creating appointment:', response.status, responseData.error);
          // Set error message and show modal
          setErrorMessage(`Error creating appointment: ${response.status} - ${responseData.error}`);
        }
      }
    } catch (error) {
      console.error('Error creating appointment:', error);
      // Handle error, maybe show an error message
    } finally {
      setLoading(false); // Set loading state to false regardless of success or failure
    }
  };
  
  // Additional useEffect for marquee animation
  useEffect(() => {
    const slides = document.querySelectorAll('.testimonial-card');
  
    // Function to start the marquee animation
    function startMarqueeAnimation() {
      slides.forEach(slide => {
        slide.classList.add('marquee');
      });
    }
  
    // Function to stop the marquee animation
    function stopMarqueeAnimation() {
      slides.forEach(slide => {
        slide.classList.remove('marquee');
      });
    }
  
    // Start the marquee animation when component mounts
    startMarqueeAnimation();
  
    // Stop the marquee animation when component unmounts
    return () => {
      stopMarqueeAnimation();
    };
  }, []);
  
  // Render the component
  return (
    <section>
      <h2>Appointment Bookings</h2>
      <section className="bio-section">
        {/* JSX code for form */}
        <section className="bio-section">
  <div className="bio-content">
    <div className="text-description">
      <h3 className="public-title">Scheduling Appointment Form</h3>
      <h3 className="note-title">Notice!</h3>
      <ul>
        <li>Service duration is added 20 minutes to cater your arrival at the spa</li>
        <li>All bookings are liable to KES, 1000 redeemable</li>
        <h3>How to pay for bookings</h3>
        <ol>
          <li>You have to fill in the date, time, therapist and room</li>
          <li>Click submit to send data</li>
          <li>You will receive an mpesa STK PUSH to enter your pin</li>
          <li>Complete mpesa transaction to receive an email invoice for your appointment</li>
          <li>You will be redirected to your appointment account automatically after successful transaction</li>
        </ol>
      </ul>

      <div className='cart-app-btns'>
        <Link className="view-button" to={`/appointments`}>
          View Appointments
        </Link>
        <Link className="view-button" to={`/cart`}>
          Visit Cart
        </Link>
      </div>
    </div>
    <div className="video-banner schedule-form-banner">
      <div className="testimonial-form-container schedule-form-banner-child">
        <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
          {/* Input fields */}
          {/* Appointment ID for editing */}
          {editingAppointment && (
            <input type="hidden" name="appointment_id" value={id} />
          )}
          {/* Service details */}
          <input type="hidden" name="service_id" value={service?.id || ''} />
          <label>Your Service</label>
          <input type="text" name="service_name" readOnly value={service?.service_name || ''} aria-readonly placeholder="Name" />
          <label>Service Duration (minutes)</label>
          <input type="number" name="service_duration" readOnly value={service?.service_duration || ''} aria-readonly placeholder="Name" />
          {/* Date and time inputs */}
          <label>Appointment Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={date => handleDateChange(date)}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Date"
            className="form-control"
          />
          <label>Appointment Time</label>
          <div className="time-inputs">
            <div className='time-labels'>
              <label htmlFor="hours">HH</label>
              {/* Select for hours */}
              <TimePicker
                disableClock={true}
                clearIcon={null}
                clockIcon={null}
                onChange={(time) => handleTimeChange(time)}
                value={selectedTime}
              />
            </div>
            <span>:</span>
            <div className='time-labels'>
              <label htmlFor="minutes">MM</label>
              {/* Select for minutes */}
              <TimePicker
                disableClock={true}
                clearIcon={null}
                clockIcon={null}
                onChange={(time) => handleTimeChange(time)}
                value={selectedTime}
              />
            </div>
            <div className='time-labels'>
              <label htmlFor="ampm">AM|PM</label>
              {/* Select for AM/PM */}
              <select value={selectedTime.slice(-2)} onChange={(e) => handleTimeChange(`${selectedTime.slice(0, -2)}${e.target.value}`)}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
          {/* Room selection */}
          <label>Choose a Room</label>
          <select className='service_room select-container' onChange={(e) => handleRoomChange(parseInt(e.target.value))}>
            {rooms.map(room => (
              <option key={room.id} value={room.id}>{room.room_name}</option>
            ))}
          </select>
          <div className="room-details select-container">
            {/* Room details */}
            {selectedRoomDetails && (
              <div>
                <h4>Room Details:</h4>
                <p>Name: {selectedRoomDetails.room_name}</p>
                {/* Add more details as needed */}
              </div>
            )}
          </div>
          {/* Therapist selection */}
          <label>Choose a Therapist</label>
          <select className='therapist' onChange={(e) => handleUserChange(Number(e.target.value))}>
            {users.map(user => (
              <option key={user.id} value={user.id}>{user.name}</option>
            ))}
          </select>
          <div className="therapist-details">
            {/* Therapist details */}
            {selectedUserDetails && (
              <div>
                <h4>Therapist Details:</h4>
                <p>Name: {selectedUserDetails.name}</p>
                {/* Add more details as needed */}
              </div>
            )}
          </div>
          {/* Submit button */}
          <button type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            ) : null}
            {loading ? 'Loading...' : 'Submit'}
          </button>
        </form>
        {/* Error modal */}
        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  </div>
</section>

      </section>
    </section>
  );
  };
  
  export default ReScheduleContent;
