// apiConfig.ts
// export const API_BASE_URL = 'https://neclaspa.co.ke/spa_barber_bookings_api/api';
export const API_BASE_URL = 'https://neclaspa.co.ke/necla-spa-server-api/api';
export const API_ENDPOINTS = {
  LOGIN: '/login',
  ADD_ROLE: '/roles', // Endpoint for adding roles
  ADD_USER: '/register', // Endpoint for adding users
  REGISTER: '/register', // Endpoint for adding users
  LOGOUT: '/logout',
  ROLES: '/roles', // Endpoint for fetching roles
  VIEW_USERS: '/viewusers', // Endpoint for fetching users
  SINGLE_USER: '/singleuser', // Updated endpoint for fetching a single user
  DELETE_USERS: '/deleteusers', // Endpoint for deleting users
  CATEGORIES: '/categories', // Updated endpoint for fetching categories
  SUBCATEGORIES: '/subcategories', // Updated endpoint for fetching categories
  ADD_SUBCATEGORY: '/subcategories', // Updated endpoint for fetching categories
  ADD_CATEGORY: '/categories', // Updated endpoint for fetching categories
  ADS: '/ads', // Updated endpoint for fetching categories
  ONEAD: '/onead', // Updated endpoint for fetching categories
  UPDATEADS: '/updateads', // Updated endpoint for fetching categories
  PUBLICADS: '/freeads', // Updated endpoint for fetching categories
  USER_ADS: '/user-ads', // Endpoint for fetching user ads
  // Add other endpoints as needed
  COLLECTIONS: '/collections', // Add the collections endpoint here
  GUEST_COLLECTIONS: '/guestcollections', // Add the collections endpoint here
  GUEST_CATEGORY_COLLECTIONS: 'guestcategorycollections',
  CATEGORY_COLLECTIONS: '/categorycollections', // Add the category endpoint here
  VIEW_ROOMS: '/rooms',
  SERVICES: '/spaservices',
  CART: '/cart',
  APPOINTMENTS: '/appointments',
  BOOKINGS: '/bookings',
  ROOMS: '/rooms',
  
  
};
