import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, Col, Row, Modal, Button, Form } from 'react-bootstrap';
import { FaCartPlus, FaArrowLeft, FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faEdit, faTrash, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import '../assets/services.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import axios from 'axios';

interface Service {
  service_duration: string;
  service_category: string | undefined;
  id: number;
  service_name: string;
  service_description: string;
  service_price: string;
  images: { id: number; image_url: string }[];
}

interface Collection {
  id: number;
  collection_name: string;
  collection_description: string;
  categories: Category[];
}

interface Category {
  category_icon: string;
  id: number;
  category_name: string;
  category_description: string;
}

interface User {
  id: number;
  name: string;
}

const GuestServicesView: React.FC = () => {
  const { collection: collectionId, category: categoryId } = useParams<{ collection: string; category: string }>();
  const [services, setServices] = useState<Service[]>([]);
  const navigate = useNavigate();
  const [collectionData, setCollectionData] = useState<Collection | null>(null);
  const [categoryData, setCategoryData] = useState<Category | null>(null);


  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.GUEST_COLLECTIONS}/${collectionId}`, {

        });

        if (response.ok) {
          const data = await response.json();
          setCollectionData(data.collection);
        } else {
          console.error('Error fetching collection');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchCollection();
  }, [collectionId]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/guestcategorycollections/${categoryId}`, {

        });
  
        if (response.ok) {
          const data = await response.json();
          setCategoryData(data.category);
        } else {
          console.error('Error fetching category');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };
  
    fetchCategory();
  }, [categoryId]);
  

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/guestspaservices`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        // Filter services based on category ID
        const filteredServices = data.spaservices.filter(
          (service: Service) => service.service_category === categoryId
        );
        setServices(filteredServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [categoryId]);

  const handleGoBack = () => {
    navigate('/services');
  };

  const handleAccessAccount = () => {
    navigate(`/login`);
  };

  const sanitizeHTML = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  return (
    <Container fluid className="mt-3 service-container">
<h1 className="text-center mb-4">
  {collectionData ? `${collectionData.collection_name} | ${categoryData ? categoryData.category_name : ''}` : 'Services'}
</h1>
{categoryData && (
    <h2 className="text-center mb-3">
      {categoryData.category_name}
    </h2>
  )}

      <Row xs={1} sm={2} md={4} lg={4} className="card-container-view">
  {services.map((service) => (
    <Col key={service.id} className="mb-4">
      <Card className="custom-card-view">
        {service.images.length > 0 && (
          <Card.Img 
            variant="top" 
            src={`${service.images[0].image_url}`} 
            alt={service.service_name} 
            className="card-img-top" 
          />
        )}
        <Card.Body>
          <hr />
          <Card.Title className="text-center">{service.service_name}</Card.Title> {/* Add text-center class */}
          <hr />
          <Card.Text>{sanitizeHTML(service.service_description)}</Card.Text>
          <Card.Text>Price: ksh. {service.service_price}</Card.Text>
          <Card.Text>Duration: {service.service_duration} minutes</Card.Text>
          <button 
            className="btn btn-primary add-cart-btn"
            onClick={() => handleAccessAccount()}
          >
            <FaCartPlus className="mr-2" />
           Access Your Account to book
          </button>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>





<div className='service-view-btns'>
      {/* Button to go back */}
      <button className="btn-go-back" onClick={handleGoBack}>
        <FaArrowLeft className="mr-2" />
      </button>
      </div>

    </Container>
  );
};

export default GuestServicesView;
