import React, { useState, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import '../../assets/css/RolePermissions.css'; // Import the CSS file
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const AddCollection: React.FC = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('accessToken');

  const [formData, setFormData] = useState({
    collection_name: '',
    collection_description: '',
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.COLLECTIONS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const collectionData = await response.json();
        console.log('Collection added successfully', collectionData);

        // Redirect to the desired page after successful addition
        navigate('/dashboard');
      } else {
        console.error('Error adding collection');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Function to handle changes in Quill editor
  const handleEditorChange = (value: string) => {
    setFormData({ ...formData, collection_description: value });
  };
  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };
  return (
    <div className="add-role-container">
      <h2>Add New Collection</h2>
      <Link to="/dashboard" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="collection_name">Collection Name:</label>
        <input
          type="text"
          id="collection_name"
          placeholder="Collection Name"
          value={formData.collection_name}
          onChange={(e) => setFormData({ ...formData, collection_name: e.target.value })}
        />

        <label htmlFor="collection_description">Collection Description:</label>
        {/* Quill editor */}
        <ReactQuill
        className='description-input'
          theme="snow"
          value={formData.collection_description}
          onChange={handleEditorChange}
          modules={modules}
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddCollection;
