// RoomsDashboard.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import Rooms from './Rooms';

const RoomsDashboard: React.FC = () => {
  return (
    <div className="admin-dashboard">
      {/* Pass the user and accessToken props to TopNavigation */}
      <TopNavigation/>
      <Row className='wrapper'>
        <Col md={2} className="sidebar">
          <Sidebar />
        </Col>
        <Col md={10} className="main-content">
          <Rooms />
        </Col>
      </Row>
    </div>
  );
};

export default RoomsDashboard;
