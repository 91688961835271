// Import necessary dependencies and styles
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/createAdStyles.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

// Import AuthContext
import { useAuth } from '../../AuthContext'; // Import useAuth

// Define the CreateAd component
const CreateAd = () => {
  // Retrieve the access token from local storage
  const accessToken = localStorage.getItem('accessToken');
  // Hook for navigating between pages
  const navigate = useNavigate();
  // Access user information from AuthContext
  const { user } = useAuth() || { user: null }; // Use useAuth and provide a default value

  // State to manage form data
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    images: [] as File[],
    video_url: '',
    category_id: 0,
    subcategory_id: 0,
    user_id: user?.id || 0, // Use optional chaining and provide a default value
    is_active: 1, // Default to inactive
    ad_type: 'product',
    price: '',
  });

  // States to store categories, subcategories, and users fetched from the API
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [users, setUsers] = useState([]);

  // useEffect hook to fetch data (categories, subcategories, and users) when the component mounts
  useEffect(() => {
    // Fetch categories from the API and update state
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCategories(data.categories);
        } else {
          console.error('Error fetching categories');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Fetch users from the API and update state
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.VIEW_USERS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
        } else {
          console.error('Error fetching users');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Call the fetch functions
    fetchCategories();
    fetchUsers();
  }, [accessToken, user?.id]); // Include user.id in the dependency array

  // Handle category change
  const handleCategoryChange = async (selectedCategoryId: number) => {
    // Fetch subcategories based on the selected category
    try {
      const response = await fetch(
        `${API_BASE_URL}${API_ENDPOINTS.SUBCATEGORIES}?category_id=${selectedCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSubcategories(data.subcategories);
      } else {
        console.error('Error fetching subcategories for the selected category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Create a new FormData object to handle file uploads
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('description', formData.description);
      formData.images.forEach((image, index) => {
        formDataToSend.append(`images[${index}]`, image);
      });
      formDataToSend.append('video_url', formData.video_url);
      formDataToSend.append('category_id', String(formData.category_id));
      formDataToSend.append('subcategory_id', String(formData.subcategory_id));
      formDataToSend.append('user_id', String(formData.user_id));
      formDataToSend.append('is_active', String(formData.is_active));
      formDataToSend.append('ad_type', formData.ad_type);
      formDataToSend.append('price', formData.price);

      // Send a POST request to the API to add an ad
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        // If the request is successful, log the data and navigate to the ads view
        const adData = await response.json();
        console.log('Ad added successfully', adData);
        navigate('/list-ads');
      } else {
        // If there is an error, log it
        console.error('Error adding ad');
      }
    } catch (error) {
      // If there is a general error, log it
      console.error('API request failed', error);
    }
  };

  // Handle image file change
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    setFormData({ ...formData, images: files });
  };

  return (
    <div className="add-role-container create-ad-container">
      <h2>Create Your Advert Here</h2>
      <Link to="/list-ads" className="role-back-button">
      Saved Adverts
        <i className="fas fa-arrow-right"></i> 
      </Link>
      <form className="add-role-form create-ad-form" onSubmit={handleSubmit}>
        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Title input */}
          <label className="create-ad-label" htmlFor="adTitle">
            Write Your Title:
          </label>
          <input
            type="text"
            id="adTitle"
            className="create-ad-input"
            placeholder="Ad title"
            value={formData.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Description textarea with ReactQuill */}
          <label className="create-ad-label" htmlFor="adDescription">
            Describe Your Advert:
          </label>
          <ReactQuill
            value={formData.description}
            onChange={(value: any) => setFormData({ ...formData, description: value })}
          />
        </div>

        <div className="form-section">
          {/* Ad Category dropdown */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adCategory">
              Categorise Your Advert:
            </label>
            <select
              id="adCategory"
              className="create-ad-select"
              value={formData.category_id}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const selectedCategoryId = Number(e.target.value);
                setFormData({ ...formData, category_id: selectedCategoryId });
                handleCategoryChange(selectedCategoryId);
              }}
            >
              <option value={0}>Select Category</option>
              {categories.map((category: any) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          {/* Ad Subcategory dropdown */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adSubcategory">
              Cluster Your Categorised Advert:
            </label>
            <select
              id="adSubcategory"
              className="create-ad-select"
              value={formData.subcategory_id}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFormData({ ...formData, subcategory_id: Number(e.target.value) })
              }
            >
              <option value={0}>Select Subcategory</option>
              {subcategories.map((subcategory: any) => (
                <option key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-section">
          {/* Ad Type dropdown */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adType">
              What Type is Your Advert?
            </label>
            <select
              id="adType"
              className="create-ad-select"
              value={formData.ad_type}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFormData({ ...formData, ad_type: e.target.value })
              }
            >
              <option value="product">Product</option>
              <option value="service">Service</option>
              <option value="info">Info</option>
            </select>
          </div>

          {/* Price input */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adPrice">
              Does it have a price?(Optional):
            </label>
            <input
              type="text"
              id="adPrice"
              className="create-ad-input"
              placeholder="Ad price"
              value={formData.price}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFormData({ ...formData, price: e.target.value })
              }
            />
          </div>
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Images file input (multiple) */}
          <label className="create-ad-label" htmlFor="adImages">
            Upload Images related to your advert(you can upload multiple):
          </label>
          <input
            type="file"
            id="adImages"
            className="create-ad-input"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          {/* Display the selected images */}
          {formData.images.length > 0 && (
            <div>
              <label className="create-ad-label">Selected Images:</label>
              {formData.images.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)} // Use createObjectURL to display the image
                  alt={`Selected Ad Image ${index + 1}`}
                  style={{ maxWidth: '100%', maxHeight: '200px' }} // Adjust the style as needed
                />
              ))}
            </div>
          )}
        </div>

        {/* Ad Video URL input */}
        <div className="create-add-form-column create-add-form-column-full-width">
          <label className="create-ad-label" htmlFor="adVideoUrl">
            Link YouTube Video URL(to enhance your advertised content):
          </label>
          <input
            type="text"
            id="adVideoUrl"
            className="create-ad-input"
            placeholder="Ad video URL"
            value={formData.video_url}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, video_url: e.target.value })
            }
          />
        </div>

        <div className="form-section">
          {/* Ad User input (disabled) */}
          <div className="create-add-form-column">
            {/* <label className="create-ad-label" htmlFor="adUserId">
              User ID:
            </label> */}
            <input
              id="adUserId"
              className="create-ad-input"
              type="hidden"
              value={formData.user_id}
              readOnly
              disabled
            />
          </div>

          {/* Is Active input (disabled) */}
          <div className="create-add-form-column">
            {/* <label className="create-ad-label" htmlFor="adIsActive">
              Is Active:
            </label> */}
            <input
              id="adIsActive"
              className="create-ad-input"
              type="hidden"
              value={formData.is_active === 1 ? 'Active' : 'Inactive'}
              readOnly
              disabled
            />
          </div>
        </div>

        {/* Submit button */}
        <button type="submit" className="create-ad-submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateAd;
