// AuthAdvertsDashboard.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopNavigation from './TopNavigation';
import AuthAdvertsContent from './AuthAdvertsContent';


const AuthAdvertsDashboard: React.FC = () => {
  return (
    <div className="admin-dashboard">
      {/* TopNavigation can access user and logout from AuthContext */}
      <TopNavigation />
      <Row className='wrapper'>
        <Col md={2} className="sidebar">
          <Sidebar />
        </Col>
        <Col md={10} className="main-content">
        <AuthAdvertsContent />
        </Col>
      </Row>
    </div>
  );
};

export default AuthAdvertsDashboard;
