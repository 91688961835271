// src/components/UserManagement/AddUserAccounts.tsx
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
// import '../../assets/css/RolePermissions.css'; 


// Define the interface for a Role
interface Role {
  id: number;
  role_name: string;
}

const AddUserAccounts = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    role_id: 1, // Set the default role ID as needed
    gender: '',
    phone: '',
    username: '',
  });

  // Explicitly define the type of the roles state
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROLES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRoles(data.roles);
        } else {
          console.error('Error fetching roles');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchRoles();
  }, [accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADD_USER}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('User added successfully', userData);

        // Redirect to the user view or any other desired page
        navigate('/system-users/view-all');
      } else {
        console.error('Error adding user');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New User</h2>
      <Link to="/system-users/view-all" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          placeholder="Name"
          value={formData.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, name: e.target.value })
          }
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, email: e.target.value })
          }
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />

        <label htmlFor="password_confirmation">Confirm Password:</label>
        <input
          type="password"
          id="password_confirmation"
          placeholder="Confirm Password"
          value={formData.password_confirmation}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, password_confirmation: e.target.value })
          }
        />

      <label htmlFor="gender">Gender:</label>
      <select
        id="gender"
        value={formData.gender}
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          setFormData({ ...formData, gender: e.target.value })
        }
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="rather_not_say">Rather Not Say</option>
      </select>


        <label htmlFor="phone">Phone:</label>
        <input
          type="text"
          id="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, phone: e.target.value })
          }
        />

        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          placeholder="Username"
          value={formData.username}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />

        <label htmlFor="role_id">Role:</label>
        <select
          id="role_id"
          value={formData.role_id}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setFormData({ ...formData, role_id: Number(e.target.value) })
          }
        >
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.role_name}
            </option>
          ))}
        </select>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
