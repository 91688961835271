// Import necessary modules and components
import React, { useState, useEffect, useRef } from 'react';
import { Container, Card, Button, Table } from 'react-bootstrap';
import '../assets/services.css'; // Import CSS file
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig'; // Import API configurations
import { useAuth } from '../AuthContext'; // Import AuthContext
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faEdit, faTrash, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import { useNavigate, Link, useLocation } from 'react-router-dom'; // Import router-related hooks
import axios from 'axios'; // Import axios for making HTTP requests

// Define types for Collection and Category
interface Collection {
  id: number;
  collection_name: string;
  collection_description: string;
  categories: Category[];
}

interface Category {
  category_icon: string;
  id: number;
  category_name: string;
  category_description: string;
}

// Define the Services component
const ServicesContent: React.FC = () => {
  // Define state variables using useState hook
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [expandedDescription, setExpandedDescription] = useState<number[]>([]);
  const navigate = useNavigate();
  const { user, logout } = useAuth(); // Access user information from useAuth hook
  const accessToken = localStorage.getItem('accessToken');
  const [userDetails, setUserDetails] = useState<any | null>(null);

  const tableRef = useRef<HTMLDivElement>(null); // Create a ref for the table container

  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch user details on component mount and when user or accessToken changes
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.data && response.data.user) {
          setUserDetails(response.data.user);
        } else {
          console.error('Invalid user data in the server response');
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    if (user) {
      fetchUserDetails();
    }
  }, [user, accessToken]);

  // Fetch collections on component mount
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        // const accessToken = localStorage.getItem('accessToken');
        // if (!accessToken) {
        //   return;
        // }
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.GUEST_COLLECTIONS}`, {
          headers: {
            // 'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCollections(data.collections);
        } else {
          console.error('Failed to fetch collections:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };
    fetchCollections();
  }, []);

  // Event handler for clicking on a card
  const handleCardClick = (collection: Collection) => {
    setSelectedCollection(collection);
    // Scroll to the table container
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleEditCollection = (collection: Collection) => {
    navigate(`/edit-collection/${collection.id}`);
  };

  const handleDeleteCollection = async (id: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.COLLECTIONS}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        console.log('Collection deleted successfully');
        const updatedCollections = collections.filter((collection: Collection) => collection.id !== id);
        setCollections(updatedCollections);
      } else {
        console.error('Error deleting collection');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const handleAddCategory = (collectionId: number) => {
    navigate(`/add-category/${collectionId}`);
  };

  const handleViewServices = (category: Category) => {
    navigate(`/guest-services/${selectedCollection?.id}/${category.id}`);
  };

  const handleEditCategory = (categoryId: number) => {
    navigate(`/edit-category/${categoryId}`);
  };
  const handleDeleteCategory = async (categoryId: number) => {
    try {
      if (selectedCollection) {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.GUEST_CATEGORY_COLLECTIONS}/${categoryId}`, {
          method: 'DELETE',
          headers: {
            // Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          console.log('Category deleted successfully');
          // Remove the deleted category from the selected collection
          const updatedCategories = selectedCollection.categories.filter(category => category.id !== categoryId);
          setSelectedCollection(prevState => ({
            ...prevState!,
            categories: updatedCategories,
          }));
        } else {
          console.error('Error deleting category');
        }
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };
  

  const toggleDescription = (categoryId: number) => {
    if (expandedDescription.includes(categoryId)) {
      setExpandedDescription(expandedDescription.filter(id => id !== categoryId));
    } else {
      setExpandedDescription([...expandedDescription, categoryId]);
    }
  };

  const stripHtmlTags = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };


  // Filter collections based on search query
  const filteredcollections = collections.filter(
    (ad) =>
      ad.collection_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentcollections = filteredcollections.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const removeHtmlTags = (htmlString: string) => {
    const regex = /(<([^>]+)>)/gi;
    return htmlString.replace(regex, '');
  };
  
  // Handler for paginating through records
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  return (
    <Container fluid className="mt-3 service-container">
      <h1 className="text-center mb-4">Services</h1>
<div>
{(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
  <>
    <h6 className="text-center service-title">click on the cards below to open services per category</h6>
  </>
) : (
  <>
    {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
      <>
        <Button className="category-btn" onClick={() => navigate('/create-collection')}>
          <FontAwesomeIcon icon={faPlus} /> Create New Collection
        </Button>
      </>
    )}
  </>
)}


</div>


    {/* Render card container */}
    <div className="card-container-services">
        {collections.map((collection, index) => (
          <Card
            key={collection.id}
            className="custom-card-services"
            style={{ backgroundColor: `hsl(${(index * 30) % 360}, 50%, 70%)`, cursor: 'pointer' }}
            onClick={() => handleCardClick(collection)}
          >
            <Card.Body>
              <Card.Title>{collection.collection_name}</Card.Title>
              <hr className="card-line" />
              <Card.Text dangerouslySetInnerHTML={{ __html: 'click to view' }} />
              
              {/* Conditional rendering for buttons */}
              {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
                <>
                </>
              ) : (
                <>
                  {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
                    <>
                      <div className="card-buttons">
                        <Button className="primary" onClick={() => handleEditCollection(collection)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteCollection(collection.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Render table container */}
    

      {selectedCollection && (
        <div ref={tableRef} className="datatable-container">
          <div className="d-flex justify-content-between mb-3">
            <h2>{selectedCollection.collection_name} Categories</h2>         
          </div>
<div className="table-container">
          <div className="role-buttons">
        {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
  <>
    <h6 className="text-center service-title">click on each category to view services for bookings</h6>
  </>
) : (
  <>
    {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
      <>
      <Button  className="category-btn" onClick={() => handleAddCategory(selectedCollection.id)}>
              <FontAwesomeIcon icon={faPlus} /> Add New Category
            </Button>
      </>
    )}
  </>
)}

      </div>

      {/* Search input and button */}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search ads..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Records per page filter */}
      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>


 {/* categories table */}
 <table className="service-custom-table tableRef">
        <thead>
        <tr>
        <th>No.</th>
            <th>Category</th>
            {/* <th>Description</th> */}
            <th>Services</th>
            {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
  <>
    {/* <h6 className="text-center service-title">click on each category to view services for bookings</h6> */}
  </>
) : (
  <>
    {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
      <>
      <th className='non-disp'>Action</th>
      </>
    )}
  </>
)}
          
          </tr>
        </thead>
        <tbody>
        {selectedCollection.categories.map((category, index: number) => (
            <tr key={category.id}>
                 <td>{index + 1}</td>
              <td>{category.category_name}</td>
              {/* <td>
                <div className="category-description">
                                  {expandedDescription.includes(category.id) ? (
                    category.category_description
                  ) : category.category_description.length > 50 ? (
                    <span>
                      {removeHtmlTags(category.category_description).substring(0, 50)}...
                    </span>
                  ) : (
                    <span>{removeHtmlTags(category.category_description)}</span>
                  )}

                  {category.category_description.length > 50 && (
                    <Button variant="link" onClick={() => toggleDescription(category.id)}>
                      {expandedDescription.includes(category.id) ? "Read Less" : "Read More"}
                    </Button>
                  )}
                </div>
              </td> */}

              <td className="service-column">
                <Button className="action-button" onClick={() => handleViewServices(category)}>
                  <FontAwesomeIcon icon={faEye} />
                  Services
                </Button>
              </td>
              {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
  <>
  </>
) : (
  <>
    {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
      <>
<td className="action-column">
                <div className="d-flex service-action-btns">
                  <Button className="action-button" onClick={() => handleEditCategory(category.id)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                  <Button className="action-button" onClick={() => handleDeleteCategory(category.id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              </td>
      </>
    )}
  </>
)}
              
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="pagination">
        <ul>
          {Array.from({ length: Math.ceil(filteredcollections.length / recordsPerPage) }).map(
            (item, index) => (
              <li key={index}>
                <button onClick={() => paginate(index + 1)}>{index + 1}</button>
              </li>
            )
          )}
        </ul>
      </div>
      </div>
</div>
      )}
    </Container>
  );
};

export default ServicesContent;
