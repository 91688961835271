// src/components/UserManagement/AddUserAccounts.tsx
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

// Define the interface for a Role
interface Role {
  id: number;
  role_name: string;
}

// Define the interface for a User
interface User {
  id: number;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  role_id: number;
  gender: string;
  phone: string;
  username: string;
}

const AddUserAccounts: React.FC = () => {
  // Retrieve access token from localStorage
  const accessToken = localStorage.getItem('accessToken');

  // Initialize the navigation hook from react-router-dom
  const navigate = useNavigate();

  // Retrieve user ID from route parameters using useParams hook
  const { userId } = useParams();

  // State variable to store form data
  const [formData, setFormData] = useState<User>({
    id: 0,
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    role_id: 1, // Set the default role ID as needed
    gender: '',
    phone: '',
    username: '',
  });

  // State variable to store roles
  const [roles, setRoles] = useState<Role[]>([]);

  // useEffect hook to fetch roles and user details when component mounts
  useEffect(() => {
    // Function to fetch roles from the API
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROLES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRoles(data.roles);
        } else {
          console.error('Error fetching roles');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Invoke the fetchRoles function
    fetchRoles();

    // Check if userId is present in route parameters
    if (userId) {
      // Function to fetch user details from the API
      const fetchUser = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/singleuser/${userId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (response.ok) {
            const userData = await response.json();
            // Populate the form data with the retrieved user details
            setFormData(userData.user);
          } else {
            console.error('Error fetching user details');
          }
        } catch (error) {
          console.error('API request failed', error);
        }
      };

      // Invoke the fetchUser function
      fetchUser();
    }
  }, [accessToken, userId]);

  // Function to handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Determine the API endpoint based on whether it's an add or edit operation
      const url = userId ? `${API_BASE_URL}/editusers/${userId}` : `${API_BASE_URL}${API_ENDPOINTS.ADD_USER}`;

      // Make a POST or PUT request to the API
      const response = await fetch(url, {
        method: userId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const userData = await response.json();
        console.log('User added/updated successfully', userData);

        // Redirect to the user view or any other desired page
        navigate('/system-users/view-all');
      } else {
        console.error('Error adding/updating user');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // JSX structure for the component
  return (
    <div className="add-role-container">
      {/* Header */}
      <h2>{userId ? 'Edit User' : 'Add New User'}</h2>

      {/* Back Button */}
      <Link to="/system-users/view-all" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>

      {/* Form */}
      <form className="add-role-form" onSubmit={handleSubmit}>
        {/* Name Field */}
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          placeholder="Name"
          value={formData.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, name: e.target.value })
          }
        />

        {/* Email Field */}
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, email: e.target.value })
          }
        />

        {/* Password Field */}
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />

        {/* Repeat Password Field */}
        <label htmlFor="password_confirmation">Confirm Password:</label>
        <input
          type="password"
          id="password_confirmation"
          placeholder="Confirm Password"
          value={formData.password_confirmation}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, password_confirmation: e.target.value })
          }
        />

       {/* Gender Field */}
<label htmlFor="gender">Gender:</label>
<select
  id="gender"
  value={formData.gender}
  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
    setFormData({ ...formData, gender: e.target.value })
  }
>
  <option value="">Select Gender</option>
  <option value="male">Male</option>
  <option value="female">Female</option>
  <option value="rather_not_say">Rather Not Say</option>
</select>

        

        {/* Phone Field */}
        <label htmlFor="phone">Phone:</label>
        <input
          type="text"
          id="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, phone: e.target.value })
          }
        />

        {/* Username Field */}
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          placeholder="Username"
          value={formData.username}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />

        {/* Role Field */}
        <label htmlFor="role_id">Role:</label>
        <select
          id="role_id"
          value={formData.role_id}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setFormData({ ...formData, role_id: Number(e.target.value) })
          }
        >
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.role_name}
            </option>
          ))}
        </select>

        {/* Submit Button */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddUserAccounts;
