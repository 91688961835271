import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../my-api/apiConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Params {
  category_id: string;
}

const AddCollection: React.FC = () => {
  const navigate = useNavigate();
  const { category_id } = useParams(); // Get the category_id from URL params
  const accessToken = localStorage.getItem('accessToken');
  const [categoryData, setCategoryData] = useState<any | null>(null);
  const [collectionId, setCollectionId] = useState<string>('');

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/categorycollections/${category_id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Response data:', data); // Log the entire response data
  
          if (data.categorycollection) { // Adjusted to check for categorycollection
            const { categorycollection, message } = data;
            setCollectionId(categorycollection.id);
            setCategoryData(categorycollection);
          } else {
            console.error('categorycollection is undefined');
          }
        } else {
          console.error('Error fetching category');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };
  
    fetchCategory();
  }, [accessToken, category_id, setCollectionId]);
  
  

  const [formData, setFormData] = useState({
    service_name: '',
    service_category: category_id || '',
    service_description: '',
    service_price: '',
    service_duration: '',
    images: [] as File[],
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
  
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('service_name', formData.service_name);
      formDataToSend.append('service_category', formData.service_category);
      formDataToSend.append('service_description', formData.service_description);
      formDataToSend.append('service_price', formData.service_price);
      formDataToSend.append('service_duration', formData.service_duration);
      formData.images.forEach((image) => {
        formDataToSend.append('images[]', image);
      });
  
      const response = await fetch(`${API_BASE_URL}/spaservices`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Service added successfully', responseData);
  
        // Navigate to the desired page after successful addition
        navigate(`/services-view/${collectionId}/${category_id}`);
      } else {
        console.error('Error adding service');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const handleEditorChange = (value: string) => {
    setFormData({ ...formData, service_description: value });
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const fileList = Array.from(files);
      setFormData({ ...formData, images: [...formData.images, ...fileList] });
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add New Service</h2>
      <Link to={`/services-view/${collectionId}/${category_id}`} className="role-back-button">
  <i className="fas fa-arrow-left"></i> Back
</Link>

      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="service_name">Service Name:</label>
        <input
          type="text"
          id="service_name"
          placeholder="Service Name"
          value={formData.service_name}
          onChange={(e) => setFormData({ ...formData, service_name: e.target.value })}
        />

        <label htmlFor="service_description">Service Description:</label>
        <ReactQuill
          className="description-input"
          theme="snow"
          value={formData.service_description}
          onChange={handleEditorChange}
        />

        <label htmlFor="service_price">Service Price:</label>
        <input
          type="text"
          id="service_price"
          placeholder="Service Price"
          value={formData.service_price}
          onChange={(e) => setFormData({ ...formData, service_price: e.target.value })}
        />

        <label htmlFor="service_duration">Service Duration:</label>
        <input
          type="text"
          id="service_duration"
          placeholder="Service Duration"
          value={formData.service_duration}
          onChange={(e) => setFormData({ ...formData, service_duration: e.target.value })}
        />

        <label htmlFor="images">Service Images:</label>
        <input
          type="file"
          id="images"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddCollection;
