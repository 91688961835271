// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/dashboard.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import { FaSearch, FaPlay, FaEye, FaThumbsUp, FaThumbsDown, FaHeart } from 'react-icons/fa'; // Import additional icons
import VideoModal from './VideoModal'; // Import the VideoModal component
import CardMenu from './CardMenu'; // Import the CardMenu component

import neclaLogo from '../components/publicpage/images/necla-logo.png'; // Import other resources as needed
import themeBanner from '../components/publicpage/images/apriltheme.jpg'; // Import the theme banner image
import counterbackImage from '../components/publicpage/images/counterback.jpg'; // Import the counterback image
import image1 from '../components/publicpage/images/image1.png'; // Import image 1
import photo6 from '../components/publicpage/images/photo 6.png'; // Import photo 6
import spa1 from '../components/publicpage/images/spa1.png'; // Import spa1 image
import neclaPoster from '../components/publicpage/images/neclaposter.jpg'; // Import necla poster image
import facialMen from '../assets/images/user.png'; // Import facial men image
import giftCard from '../components/publicpage/images/gift-card.png'; // Import facial her 1 image

// import image1 from '../assets/images/neclaposter.png';
// import image2 from '../assets/images/nature.jpg';
// Import other images as needed

// Define interfaces for Ad and AdWithDetails
interface Ad {
  id: number;
  title: string;
  description: string;
  image_url?: string | null;
  video_url?: string | null;
  category_id: number;
  subcategory_id: number;
  user_id: number;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  ad_type: string;
  price: number | null;
  default_image: { id: number; image_url: string } | null;
  likes: number;
  dislikes: number;
  views: number;
  badge: string;
}

export interface AdWithDetails extends Ad {}

// Define the AdvertsContent component
const AdvertsContent: React.FC = () => {
  // Create necessary state variables
  const navigate = useNavigate();
  const { user } = useAuth();
  const [ads, setAds] = useState<Ad[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAd, setSelectedAd] = useState<AdWithDetails | null>(null); // State for selected ad to display the modal
  const accessToken = localStorage.getItem('accessToken');
  // Fetch ads from the API
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },

        });
        

        if (response.ok) {
          const data = await response.json();
          setAds(data.ads);
        } else {
          console.error('Error fetching ads');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchAds();
  }, [accessToken]);

  // Filter ads based on search query
  const filteredAds = ads.filter(
    (ad) =>
      ad.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ad.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate the displayed ads
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAds = filteredAds.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handle change in records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Handle pagination
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle play button click
  const handlePlayButtonClick = (ad: AdWithDetails) => {
    setSelectedAd(ad);
  };

  // Handle modal close
  const closeModal = () => {
    setSelectedAd(null);
  };

  


  useEffect(() => {
    const prevButton = document.querySelector('.prev');
    const nextButton = document.querySelector('.next');
    const slides = document.querySelectorAll('.slide');

    if (prevButton && nextButton) {
        prevButton.addEventListener('click', () => {
            clearInterval(slideInterval); // Clear interval when user interacts with the slider
            moveSlide(-1);
            startAutoSlide(); // Restart auto sliding after manual interaction
        });

        nextButton.addEventListener('click', () => {
            clearInterval(slideInterval); // Clear interval when user interacts with the slider
            moveSlide(1);
            startAutoSlide(); // Restart auto sliding after manual interaction
        });
    }

    let slideIndex = 0;
    let slideInterval: NodeJS.Timeout; // Variable to store the interval

    function startAutoSlide() {
        slideInterval = setInterval(() => {
            moveSlide(1);
        }, 3000); // Adjust the interval duration as needed
    }

    function moveSlide(direction: number) {
        slideIndex += direction;

        if (slideIndex < 0) {
            slideIndex = slides.length - 1;
        } else if (slideIndex >= slides.length) {
            slideIndex = 0;
        }

        slides.forEach(slide => {
            (slide as HTMLElement).style.display = 'none';
        });

        (slides[slideIndex] as HTMLElement).style.display = 'block';
    }

    startAutoSlide(); // Start auto sliding when component mounts

    return () => {
        clearInterval(slideInterval); // Clear interval when component unmounts
    };
}, []);

useEffect(() => {
  const slides = document.querySelectorAll('.testimonial-card');

  // Function to start the marquee animation
  function startMarqueeAnimation() {
    slides.forEach(slide => {
      slide.classList.add('marquee');
    });
  }

  // Function to stop the marquee animation
  function stopMarqueeAnimation() {
    slides.forEach(slide => {
      slide.classList.remove('marquee');
    });
  }

  // Start the marquee animation when component mounts
  startMarqueeAnimation();

  // Stop the marquee animation when component unmounts
  return () => {
    stopMarqueeAnimation();
  };
}, []);
  return (
    <div className="dashboard-content">
      
      <section className="theme-month">
        <div className="theme-content">
          <div className="theme-banner">
            <h3 className="public-title">Theme of the Month</h3>
            <img src={themeBanner} alt="Theme of the Month Banner" />
          </div>

          <div className="theme-text">

            <h3 className="slogan public-title">Happy new month</h3>
            <p className="description">We as Necla Spa & Barbershop wish all our esteemed clients a Happy New and Holistic Month. 
              Welcome to Necla Spa & Barbershop for all you Holistic Spa & Barber concerns.

            </p>
            <p className="description">
            <Link className="view-button" to={`/auth-services`}>
                Schedule Appointment
            </Link>
            </p>

          </div>
        </div>
        
      </section>
      <hr></hr>


      
      {/* Banner with search form and background image */}

      {/* Advertisements section */}
      <h2 className='dash-title'>Our Advertisements</h2>
      <div className="advertisements-container">
        {currentAds.map((ad: AdWithDetails) => {
          // Fetch the default image
          const defaultImage = ad.default_image;

          return (
            <div key={ad.id} className="advertisement-card">
              <div className='advert-image-holder'>
                {defaultImage ? (
                  <img
                    src={defaultImage.image_url}
                    alt={`Ad Image ${ad.id}`}
                    className="advertisement-image"
                  />
                ) : (
                  <div className="default-image-placeholder">
                    {/* Display default image placeholder */}
                  </div>
                )}
              </div>
              <div className="advert-card-content">
                <div className='non-title-content'>
                 
                  <div className="likes-dislikes">
                    <span>
                      <FaThumbsUp />30 {ad.likes}
                    </span>
                    {/* <span>
                      <FaThumbsDown /> {ad.dislikes}
                    </span> */}
                  </div>
                  <div className="likes-dislikes">
                    {/* <span>
                      <FaThumbsUp /> {ad.likes}
                    </span> */}
                    <span>
                      <FaThumbsDown />0 {ad.dislikes}
                    </span>
                  </div>
                  <div className="views-count">
                    <span>
                      <FaEye />10 {ad.views}
                    </span>
                  </div>
                  <div className="glowing-badge">
                    <FaHeart />{ad.badge}
                  </div>
                   
                  <p className='ad-type'>{ad.ad_type}</p>
                </div>
                <div className="star-rating">
                    <p>Editor:{ad.user_full_name}</p>
                    ★★★★★
                  </div>
              </div>
              <div className='public-content-title'>
              <p>{ad.title}</p>
              </div>
              <div className="button-icons">
              <Link className="view-button" to={`/view-ad/${ad.id}`}>
                View more...
              </Link>
               
                {/* ... (existing code) ... */}
              </div>
            </div>
          );
        })}
      </div>

      {/* Render VideoModal if selectedAd is not null */}
      {selectedAd && <VideoModal ad={selectedAd} onClose={closeModal} />}

      {/* Pagination section */}
      <div className="pagination-container">
        {/* <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button> */}
        <button  onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} ><i className="fas fa-chevron-left"></i></button>
        {Array.from({ length: Math.ceil(filteredAds.length / recordsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
        {/* <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredAds.length / recordsPerPage)}
        >
          Next
        </button> */}
        <button onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredAds.length / recordsPerPage)} ><i className="fas fa-chevron-right"></i></button>
      </div>
    <hr></hr>
            {/* <section className="banner"> */}
            <div className="image-slider">
        <div className="slide-window">
          <div className="slide"><img src={counterbackImage} alt="Image 1" /></div>
          <div className="slide"><img src={image1} alt="Image 2" /></div>
          <div className="slide"><img src={photo6} alt="Image 3" /></div>
          <div className="slide"><img src={spa1} alt="Image 2" /></div>
          <div className="slide"><img src={neclaPoster} alt="Image 3" /></div>
          {/* Add more slides as necessary */}
        </div>
        <button className="prev"><i className="fas fa-chevron-left"></i></button>
        <button className="next"><i className="fas fa-chevron-right"></i></button>
      </div>
      {/* </section> */}
      <hr></hr>
      <section className="gift-cards-section">
      <h2 className="gift-cards-title">Gift Cards</h2>
      <div className="gift-cards-grid">
        <a href="gift-card-link-1.html" className="gift-card">
          <div className="gift-card-image">
            <img src={giftCard} alt="Gift Card 1" />
          </div>
          <h3 className="gift-card-title">Birthday Special</h3>
          {/* <button className="want-gift-btn">Want this Gift</button> */}
        </a>
        <a href="gift-card-link-1.html" className="gift-card">
          <div className="gift-card-image">
            <img src={giftCard} alt="Gift Card 1" />
          </div>
          <h3 className="gift-card-title">Valentine Special</h3>
          {/* <button className="want-gift-btn">Want this Gift</button> */}
        </a>
        <a href="gift-card-link-1.html" className="gift-card">
          <div className="gift-card-image">
            <img src={giftCard} alt="Gift Card 1" />
          </div>
          <h3 className="gift-card-title">Couple Special</h3>
          {/* <button className="want-gift-btn">Want this Gift</button> */}
        </a>
        <a href="gift-card-link-1.html" className="gift-card">
          <div className="gift-card-image">
            <img src={giftCard} alt="Gift Card 1" />
          </div>
          <h3 className="gift-card-title">Mothers Special</h3>
          {/* <button className="want-gift-btn">Want this Gift</button> */}
        </a>
        <a href="gift-card-link-1.html" className="gift-card">
          <div className="gift-card-image">
            <img src={giftCard} alt="Gift Card 1" />
          </div>
          <h3 className="gift-card-title">Fathers Special</h3>
          {/* <button className="want-gift-btn">Want this Gift</button> */}
        </a>
      </div>
    </section>
    <hr></hr>
    <section className="packages-section">
      <h2 className="packages-title">Our Packages</h2>
      <div className="packages-grid">
        <div className="package-card">
          <h3 className="package-type">Bronze</h3>
          {/* <p className="package-details">Details about the Bronze package...</p> */}
          {/* <p className="package-price">Ksh 50</p> */}
          {/* <button className="purchase-btn">Purchase</button> */}
        </div>

        <div className="package-card">
          <h3 className="package-type">Silver</h3>
          {/* <p className="package-details">Details about the Bronze package...</p> */}
          {/* <p className="package-price">Ksh 50</p> */}
          {/* <button className="purchase-btn">Purchase</button> */}
        </div>

        <div className="package-card">
          <h3 className="package-type">Gold</h3>
          {/* <p className="package-details">Details about the Bronze package...</p> */}
          {/* <p className="package-price">Ksh 50</p> */}
          {/* <button className="purchase-btn">Purchase</button> */}
        </div>

        <div className="package-card">
          <h3 className="package-type">Diamond</h3>
          {/* <p className="package-details">Details about the Bronze package...</p> */}
          {/* <p className="package-price">Ksh 50</p> */}
          {/* <button className="purchase-btn">Purchase</button> */}
        </div>
        {/* Create more package cards by replicating the above structure */}
      </div>
    </section>
    <hr></hr>
{/*     
    <section className="testimonials">
        <h3 className="testimonial-title public-title">Testimonials</h3>
        <div className="slider">
          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>
          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>
        </div>
      </section>

      <hr></hr> */}
      <footer>
        <div className="footer-content">
          {/* Logo */}
          <div className="footer-logo">
            <img src={neclaLogo} alt="Necla Logo" />
          </div>
          
          {/* Social Media Icons */}
          <div className="footer-social">
            <a href="https://web.facebook.com/NeclaBeautyandSpa/?_rdc=1&_rdr"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/neclaspabarber"><i className="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/explore/locations/114366393540115/necla-spa-barbershop/"><i className="fab fa-instagram"></i></a>
            {/* <a href="#"><i className="fab fa-linkedin-in"></i></a> */}
          </div>
          
          {/* Contact Us Details */}
          <div className="footer-contact">
            <p>info@neclaspa.co.ke</p>
            <p>+254 721 313219</p>
            <p>Northpark Hub Mall 3rd floor Suit-T2, Ruiru Bypass, Kamakis Road, Nairobi, Kenya</p>
        </div>
        
          
          {/* Copyright Protection */}
          <div className="footer-copyright">
            <p>&copy; <span id="currentYear"></span> Necla Spa & Barber. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AdvertsContent;
