
// MobileSidebar.tsx
import React, { useState, useEffect } from 'react';
import { FaTimes, FaSearch } from 'react-icons/fa';

import '../../src/assets/css/MobileSidebar.css';
// Sidebar.tsx

import { Button, Nav } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  FaHome,
  FaAd,
  FaUser,
  FaChartBar,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserPlus,
  FaCalendarAlt,
  FaShoppingCart,
  FaCalendarCheck, // Booking icon
  FaDoorOpen, // Room icon
  FaBloggerB, // Blogging icon
  FaPlusSquare, // Add blog icon
  FaInfo,
  FaPhone,
  FaCog,
  FaShoppingBag,
  FaBullhorn,
} from 'react-icons/fa';
import SidebarLogo from '../assets/images/advert-logo.png';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import profilePictureSrc from '../assets/images/user.png';


interface MobileSidebarProps {
  onClose: () => void;
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({ onClose }) => {
    const accessToken = localStorage.getItem('accessToken');
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetails, setUserDetails] = useState<any | null>(null);
    const [logoClass, setLogoClass] = useState('sidebar-logo-watch');
    const [isHovered, setIsHovered] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = () => {
      // Add logic to handle search (e.g., redirect to search results)
      console.log('Search query:', searchQuery);
    };
    useEffect(() => {
      // Start the flipping animation infinitely when the component mounts
      const intervalId = setInterval(() => {
        if (!isHovered) {
          setLogoClass((prevClass) =>
            prevClass === 'sidebar-logo-watch' ? 'sidebar-logo-watch flipped' : 'sidebar-logo-watch'
          );
        }
      }, 1000);
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, [isHovered]);
  
    useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (response.data && response.data.user) {
            setUserDetails(response.data.user);
          } else {
            console.error('Invalid user data in the server response');
          }
        } catch (error) {
          console.error('Failed to fetch user details:', error);
        }
      };
  
      if (user) {
        fetchUserDetails();
      }
    }, [user, accessToken]);

    const handleLogout = async () => {
      try {
        if (accessToken) {
          // Send a logout request to the server with the access token
          await axios.post(
            `${API_BASE_URL}${API_ENDPOINTS.LOGOUT}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
  
          // Clear the user and access token from local storage
          logout();
  
          // Navigate to the login page
          navigate('/welcomepage', { replace: true });
        } else {
          console.error('Access token not found.');
        }
      } catch (error: any) {
        console.error('Logout failed:', error.message);
      }
    };
  return (
    <div className="mobile-sidebar">
      <div className="close-button" onClick={onClose}>
        <FaTimes />
      </div>
       {/* Search Form */}
       <form className="search-form" onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          className="search-input"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button type="button" className="search-button" onClick={handleSearch}>
          <FaSearch />
        </button>
      </form>
      <Nav defaultActiveKey="/dashboard" className="flex-column">
        {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
          <>

<Link to="/auth-adverts" className={`sidebar-link ${location.pathname === '/auth-adverts' ? 'active' : ''}`}>
        <FaBullhorn className="sidebar-icon" />
        Adverts
        </Link>

        <Link to="/welcomepage" className={`sidebar-link ${location.pathname === '/welcomepage' ? 'active' : ''}`}>
        <FaHome className="sidebar-icon" />
        Home
        </Link>
        
        <Link to="/appointments" className={`sidebar-link ${location.pathname === '/appointments' ? 'active' : ''}`}>
        <FaCalendarAlt className="sidebar-icon" />
        Appointments
        </Link>
        {/* <Link to="/dashboard" className={`sidebar-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
        <FaHome className="sidebar-icon" />
        Browse
        </Link> */}

        <Link to="/auth-services" className={`sidebar-link ${location.pathname === '/auth-services' ? 'active' : ''}`}>
        <FaPlusSquare className="sidebar-icon" />
        Services
        </Link>
        {/* <Link to="/auth-products" className={`sidebar-link ${location.pathname === '/auth-products' ? 'active' : ''}`}>
        <FaShoppingBag className="sidebar-icon" />
        Products
        </Link> */}
        <Link to="/cart" className={`sidebar-link ${location.pathname === '/cart' ? 'active' : ''}`}>
        <FaShoppingCart className="sidebar-icon" />
        Your Cart
        </Link>
      <Link to="/about" className={`sidebar-link ${location.pathname === '/about' ? 'active' : ''}`}>
        <FaInfo className="sidebar-icon" />
        About Us
      </Link>
      <Link to="/contact" className={`sidebar-link ${location.pathname === '/contact' ? 'active' : ''}`}>
        <FaPhone className="sidebar-icon" />
        Contact Us
      </Link>
      <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt />
          Logout
        </button>
            {/* <Link to="/profile" className={`sidebar-link ${location.pathname === '/profile' ? 'active' : ''}`}>
              <FaUser className="sidebar-icon" />
              Your Profile
            </Link> */}
          </>
        ) : (
          <>
            {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
              <>
                 <Link
                  to="/dashboard"
                  className={`sidebar-link ${location.pathname === '/dashboard' ? 'active' : ''}`}
                >
                  <FaHome className="sidebar-icon" />
                  Admin Browser
                </Link>
                <Link to="/bookings" className={`sidebar-link ${location.pathname === '/bookings' ? 'active' : ''}`}>
              <FaCalendarCheck className="sidebar-icon" />
              Bookings
            </Link>
            <Link to="/rooms" className={`sidebar-link ${location.pathname === '/rooms' ? 'active' : ''}`}>
              <FaDoorOpen className="sidebar-icon" />
              Rooms
            </Link>
                <Link
                  to="/advertisements"
                  className={`sidebar-link ${location.pathname === '/advertisements' ? 'active' : ''}`}
                >
                  <FaBloggerB className="sidebar-icon" />
                  Blogging
                </Link>
                {/* <Link to="/create-ad" className={`sidebar-link ${location.pathname === '/create-ad' ? 'active' : ''}`}>
              <FaPlusSquare className="sidebar-icon" />
              Create Blog
            </Link>
            <Link to="/list-ads" className={`sidebar-link ${location.pathname === '/list-ads' ? 'active' : ''}`}>
              <FaAd className="sidebar-icon" />
              Unapproved Blogs
            </Link> */}
                <Link
                  to="/user-management"
                  className={`sidebar-link ${location.pathname === '/users' ? 'active' : ''}`}
                >
                  <FaUser className="sidebar-icon" />
                  Accounts
                </Link>
                {/* <Link to="/analytics" className={`sidebar-link ${location.pathname === '/analytics' ? 'active' : ''}`}>
                  <FaChartBar className="sidebar-icon" />
                  Analytics
                </Link> */}
                {/* <Link to="/settings" className={`sidebar-link ${location.pathname === '/settings' ? 'active' : ''}`}>
                  <FaCog className="sidebar-icon" />
                  Settings
                </Link> */}
      <Button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt />
          Logout
        </Button>
              </>
            )}
            {!user && (
              <>
      <Link to="/welcomepage" className={`sidebar-link ${location.pathname === '/welcomepage' ? 'active' : ''}`}>
        <FaHome className="sidebar-icon" />
        Home
      </Link>
      <Link to="/adverts" className={`sidebar-link ${location.pathname === '/adverts' ? 'active' : ''}`}>
        <FaBullhorn className="sidebar-icon" />
        Adverts
      </Link>
      <Link to="/services" className={`sidebar-link ${location.pathname === '/services' ? 'active' : ''}`}>
        <FaPlusSquare className="sidebar-icon" />
        Services
      </Link>
      <Link to="/products" className={`sidebar-link ${location.pathname === '/products' ? 'active' : ''}`}>
        <FaShoppingBag className="sidebar-icon" />
        Products
      </Link>
      <Link to="/about" className={`sidebar-link ${location.pathname === '/about' ? 'active' : ''}`}>
        <FaInfo className="sidebar-icon" />
        About Us
      </Link>
      <Link to="/contact" className={`sidebar-link ${location.pathname === '/contact' ? 'active' : ''}`}>
        <FaPhone className="sidebar-icon" />
        Contact Us
      </Link>
      <Link to="/login" className={`sidebar-link ${location.pathname === '/login' ? 'active' : ''}`}>
        <FaSignInAlt className="sidebar-icon" />
        Login
      </Link>
      <Link to="/register" className={`sidebar-link ${location.pathname === '/register' ? 'active' : ''}`}>
        <FaUserPlus className="sidebar-icon" />
        Register
      </Link>
              </>
            )}
          </>
        )}
      </Nav>
    </div>
  );
};

export default MobileSidebar;
