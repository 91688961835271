import React, { useState, FormEvent, ChangeEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import '../../assets/css/RolePermissions.css'; // Import the CSS file
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

interface Params {
  collection_id: string;
}

const AddCollection: React.FC = () => {
  const navigate = useNavigate();
  const { collection_id } = useParams(); // Get the collection_id from URL params
  const accessToken = localStorage.getItem('accessToken');

  const [formData, setFormData] = useState({
    category_name: '',
    category_description: '',
    collection_id: collection_id || '', // Fill collection_id with the parameter from URL or empty string
    category_icon: null as File | null, // Initialize category_icon as null
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('category_name', formData.category_name);
      formDataToSend.append('category_description', formData.category_description);
      formDataToSend.append('collection_id', formData.collection_id);
      if (formData.category_icon) {
        formDataToSend.append('category_icon', formData.category_icon);
      }

      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORY_COLLECTIONS}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const categoryData = await response.json();
        console.log('Category added successfully', categoryData);

        // Redirect to the desired page after successful addition
        navigate('/dashboard');
      } else {
        console.error('Error adding category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Function to handle changes in Quill editor
  const handleEditorChange = (value: string) => {
    setFormData({ ...formData, category_description: value });
  };

  const handleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFormData({ ...formData, category_icon: files[0] });
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  return (
    <div className="add-role-container">
      <h2>Add New Collection</h2>
      <Link to="/dashboard" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="category_name">Category Name:</label>
        <input
          type="text"
          id="category_name"
          placeholder="Category Name"
          value={formData.category_name}
          onChange={(e) => setFormData({ ...formData, category_name: e.target.value })}
        />

        <label htmlFor="category_description">Category Description:</label>
        {/* Quill editor */}
        <ReactQuill
          className='description-input'
          theme="snow"
          value={formData.category_description}
          onChange={handleEditorChange}
          modules={modules}
        />

        <label htmlFor="category_icon">Category Icon:</label>
        <input
          type="file"
          id="category_icon"
          accept="image/*"
          onChange={handleIconChange}
        />

        {/* <label htmlFor="collection_id">Collection ID:</label> */}
        <input
          type="hidden"
          id="collection_id"
          value={formData.collection_id}
          readOnly
        />

        {/* Add more fields here if needed */}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddCollection;
