import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

interface Category {
  id: number;
  name: string;
  description: string;
}

const AddSubcategory = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    category_id: '',
  });

  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCategories(data.categories);
        } else {
          console.error('Error fetching categories');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchCategories();
  }, [accessToken]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADD_SUBCATEGORY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const subcategoryData = await response.json();
        console.log('Subcategory added successfully', subcategoryData);

        // Redirect to the subcategory view or any other desired page
        navigate('/sub-categories');
      } else {
        console.error('Error adding subcategory');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Add Subcategory</h2>
      <Link to="/sub-categories" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="subcategoryName">Subcategory Name:</label>
        <input
          type="text"
          id="subcategoryName"
          placeholder="Subcategory name"
          value={formData.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, name: e.target.value })
          }
        />

        <label htmlFor="categoryId">Category:</label>
        <select
          id="categoryId"
          value={formData.category_id}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setFormData({ ...formData, category_id: e.target.value })
          }
        >
          <option value="" disabled>
            Select a category
          </option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddSubcategory;
