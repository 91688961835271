// UserProfileSidebar.tsx
import React from 'react';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import '../assets/css/UserProfileSidebar.css';

interface UserProfileSidebarProps {
  onClose: () => void;
}

const UserProfileSidebar: React.FC<UserProfileSidebarProps> = ({ onClose }) => {
  const { user, logout } = useAuth();
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      if (accessToken) {
        // Send a logout request to the server with the access token
        await axios.post(
          `${API_BASE_URL}${API_ENDPOINTS.LOGOUT}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // Clear the user and access token from local storage
        logout();

        // Navigate to the login page
        navigate('/welcomepage', { replace: true });
      } else {
        console.error('Access token not found.');
      }
    } catch (error: any) {
      console.error('Logout failed:', error.message);
    }

    // Close the sidebar after logout
    onClose();
  };

  return (
    <div className="user-profile-sidebar">
      <div className="user-profile-header">
        <FaUser size={30} />
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="user-profile-content">
        {user && (
          <div className="profile-info">
            <p>Name: {user.name}</p>
            <p>Email: {user.email}</p>
          </div>
        )}
        <button className="profile-button">
          <span>Profile</span>
        </button>
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt />
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserProfileSidebar;
