import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Card, Col, Row, Modal, Button, Form } from 'react-bootstrap';
import { FaCartPlus, FaArrowLeft, FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faEdit, faTrash, faEye, faPlus } from '@fortawesome/free-solid-svg-icons'; // Import FontAwesome icons
import '../assets/services.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import axios from 'axios';

interface Service {
  service_duration: string;
  service_category: string | undefined;
  id: number;
  service_name: string;
  service_description: string;
  service_price: string;
  images: { id: number; image_url: string }[];
}

interface Collection {
  id: number;
  collection_name: string;
  collection_description: string;
  categories: Category[];
}

interface Category {
  category_icon: string;
  id: number;
  category_name: string;
  category_description: string;
}

interface User {
  id: number;
  name: string;
}

const ServicesView: React.FC = () => {
  const { collection: collectionId, category: categoryId } = useParams<{ collection: string; category: string }>();
  const [services, setServices] = useState<Service[]>([]);
  const [showCartModal, setShowCartModal] = useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');
  const [collectionData, setCollectionData] = useState<Collection | null>(null);
  const [categoryData, setCategoryData] = useState<Category | null>(null);
  const [userDetails, setUserDetails] = useState<any | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);


    // Fetch user details on component mount and when user or accessToken changes
    useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (response.data && response.data.user) {
            setUserDetails(response.data.user);
          } else {
            console.error('Invalid user data in the server response');
          }
        } catch (error) {
          console.error('Failed to fetch user details:', error);
        }
      };
  
      if (user) {
        fetchUserDetails();
      }
    }, [user, accessToken]);

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.COLLECTIONS}/${collectionId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCollectionData(data.collection);
        } else {
          console.error('Error fetching collection');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchCollection();
  }, [accessToken, collectionId]);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/categorycollections/${categoryId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setCategoryData(data.category);
        } else {
          console.error('Error fetching category');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };
  
    fetchCategory();
  }, [accessToken, categoryId]);
  

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/spaservices`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        // Filter services based on category ID
        const filteredServices = data.spaservices.filter(
          (service: Service) => service.service_category === categoryId
        );
        setServices(filteredServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [accessToken, categoryId]);

  const handleGoBack = () => {
    navigate('/dashboard');
  };

  const handleCreateNewService = () => {
    // Navigate to the create-service route with the category_id included in the URL
    navigate(`/create-service/${categoryId}`);
  };

  const handleAddToCart = async (serviceId: number) => {
    try {
      const formData = new FormData();
      formData.append('user_id', user?.id.toString() || '');
      formData.append('service_id', serviceId.toString());
      formData.append('status', 'pending');

      const response = await fetch(`${API_BASE_URL}/cart`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }

      // Navigate to the cart page with the service ID included in the URL
      navigate(`/cart`);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const sanitizeHTML = (html: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.VIEW_USERS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200) {
          setUsers(response.data.users);
        } else {
          throw new Error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, [accessToken]);

  const handleAddToBasket = async (serviceId: number) => {
    if (!selectedUser) {
      // Show a message or toast indicating that a user must be selected
      return;
    }
    try {
      const formData = new FormData();
      formData.append('user_id', selectedUser.toString());
      formData.append('service_id', serviceId.toString());
      formData.append('status', 'pending');
  
      const response = await axios.post(`${API_BASE_URL}/cart`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        // Navigate to the cart page
        navigate(`/cart`);
      } else {
        throw new Error('Failed to add service to basket');
      }
    } catch (error) {
      console.error('Error adding service to basket:', error);
      // Handle the error here
    }
  };
  

  return (
    <Container fluid className="mt-3 service-container">
<h1 className="text-center mb-4">
  {collectionData ? `${collectionData.collection_name} | ${categoryData ? categoryData.category_name : ''}` : 'Book Services'}
</h1>
{categoryData && (
    <h2 className="text-center mb-3">
      {categoryData.category_name}
    </h2>
  )}

      <Row xs={1} sm={2} md={4} lg={4} className="card-container-view">
  {services.map((service) => (
    <Col key={service.id} className="mb-4">
      <Card className="custom-card-view">
        {service.images.length > 0 && (
          <Card.Img 
            variant="top" 
            src={`${service.images[0].image_url}`} 
            alt={service.service_name} 
            className="card-img-top" 
          />
        )}
        <Card.Body>
          <hr />
          <Card.Title className="text-center">{service.service_name}</Card.Title> {/* Add text-center class */}
          <hr />
          <Card.Text>{sanitizeHTML(service.service_description)}</Card.Text>
          <Card.Text>Price: ksh. {service.service_price}</Card.Text>
          <Card.Text>Duration: {service.service_duration} minutes</Card.Text>
          {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
            <>
          <button 
            className="btn btn-primary add-cart-btn"
            onClick={() => handleAddToCart(service.id)}
          >
            <FaCartPlus className="mr-2" />
            Add to Cart
          </button>
            </>
          ) : (
            <>
              {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
                <>
                <div className='users-bookings'>
                <Form.Group controlId="selectUser">
        {/* <Form.Label>Select User:</Form.Label> */}
        <Form.Control as="select" onChange={(e) => setSelectedUser(Number(e.target.value))}>
          <option value="">Select a user</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Button to add service to basket */}
      <Button variant="primary" onClick={() => handleAddToBasket(service.id)}>
        <FaCartPlus className="mr-2" />
        Add to Basket
      </Button>
                </div>
<hr></hr>
                  <div className="service-card-btns">
                          {/* Dropdown to select user */}

                        <Button>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                </>
              )}
            </>
          )}

        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>





<div className='service-view-btns'>
      {/* Button to go back */}
      <button className="btn-go-back" onClick={handleGoBack}>
        <FaArrowLeft className="mr-2" />
      </button>
      {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
  <>
    {/* <h6 className="text-center service-title">click on each category to view services for bookings</h6> */}
  </>
) : (
  <>
    {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
      <>
      <button className="btn-floating-create" onClick={handleCreateNewService}>
        <FaPlus />
      </button>
      </>
    )}
  </>
)}

</div>

    </Container>
  );
};

export default ServicesView;
