// src/components/LoginForm.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import useAuth
import '../assets/LoginForm.css';
import logoImage from '../assets/images/logo.png';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';


const LoginForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.REGISTER}`, {
        name,
        email,
        username,
        phone,
        gender,
        password,
        password_confirmation: passwordConfirmation,
      });

      const { message, user, access_token } = response.data;

      if (message === 'User created successfully') {
        // login(user, access_token);
        navigate('/login');
      } else {
        setErrorMessage('Registration failed. Please check your details.');
      }
    } catch (error: any) {
      console.error('Registration failed:', error.message);
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="login-background-container">
      <div className="login-form-wrapper">
        <div className="login-form">
          <img src={logoImage} alt="Logo" className="logo" />
          <h3 style={{ color: '#ffffe', textAlign: 'center' }}>Create Account</h3>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <div className="form-group">
            <input
              className='login-input'
              type="text"
              id="name"
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              className='login-input'
              type="email"
              id="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              className='login-input'
              type="text"
              id="username"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              className='login-input'
              type="tel"
              id="phone"
              placeholder="Enter Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <select
              className='login-input'
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <input
              className='login-input'
              type="password"
              id="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              className='login-input'
              type="password"
              id="password_confirmation"
              placeholder="Confirm Password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
          </div>
          <button className='login-button' type="submit" onClick={handleRegister}>
            Register
          </button>
        </div>
        <h3 style={{ color: '#ffffe', textAlign: 'center' }}>Login if already have an account</h3>
        <div className="login-nav-buttons">
        <Link to="/login">
        Login
        </Link>

  
   <Link to="/welcomepage" className='login-button'>
    Go Back
  </Link>
        
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
