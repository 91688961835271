import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';

interface Appointment {
    id: number;
    cart_id: number;
    room_id: number;
    booking_date: string;
    booking_time: string;
    booking_duration: number;
    booking_status: 'pending' | 'completed' | 'cancelled' | 'Expired'; // Assuming booking_status can have specific values
    therapist_id: number;
    cartpage: CartPageDetails;
    room: RoomDetails;
    therapist: TherapistDetails;
  }
  
  interface CartPageDetails {
    user_id: number;
    service_id: number;
    status: 'pending' | 'confirmed' | 'cancelled'; // Assuming status can have specific values
    spaservice: SpaServiceDetails;
    usercart: UserDetails; // Add user property
  }
  
  interface RoomDetails {
    room_name: string;
    room_description: string;
  }
  
  interface TherapistDetails {
    name: string;
    email: string;
    username: string;
    phone: string;
    gender: 'male' | 'female' | 'other'; // Assuming gender can have specific values
  }
  
  interface SpaServiceDetails {
    id: number;
    service_name: string;
    service_category: string; // Assuming service_category type
    service_description: string;
    service_price: string; // Assuming price is a string, change to number if applicable
    service_duration: number;
    created_at: string; // Assuming datetime format
    updated_at: string; // Assuming datetime format
  }
  
  interface UserDetails {
    id: number;
    name: string;
    email: string;
    username: string;
    phone: string;
    gender: 'male' | 'female' | 'other';
  }
  
  

const AdsManagement: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAppointments(data.appointments);
        } else {
          setErrorMessage('Error fetching appointments');
        }
      } catch (error) {
        console.error('API request failed', error);
        setErrorMessage('Error fetching appointments');
      }
    };

    fetchAppointments();
  }, [accessToken]);

    // Handler for navigating to the edit page
    const handleEdit = (adId: number) => {
        console.log(`Editing ad with ID ${adId}`);
        navigate(`/edit-ad/${adId}`);
      };
    
      // Handler for deleting an ad
      const handleDelete = async (adId: number) => {
        try {
          const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${adId}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
    
          if (response.ok) {
            console.log(`Ad with ID ${adId} deleted successfully`);
            setSuccessMessage(`Ad with ID ${adId} deleted successfully`);
            window.location.reload();
          } else {
            console.error(`Error deleting ad with ID ${adId}`);
          }
        } catch (error) {
          console.error('API request failed', error);
        }
      };
    
      // Filter ads based on search query
      const filteredAppointments = appointments.filter(
        (appointment) =>
        appointment.booking_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        appointment.booking_date.toLowerCase().includes(searchQuery.toLowerCase())
      );
    
      // Pagination logic
      const indexOfLastRecord = currentPage * recordsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
      const currentAppointments = filteredAppointments.slice(indexOfFirstRecord, indexOfLastRecord);
    
      // Handler for changing records per page
      const handleRecordsPerPageChange = (value: number) => {
        setRecordsPerPage(value);
        setCurrentPage(1);
      };
    
      // Extract YouTube video ID from the video URL
      const getYouTubeVideoId = (url: string): string | null => {
        const match = url.match(
          /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
        );
        return match ? match[1] : null;
      };
    
      // Handler for paginating through records
      const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

// Handler for updating appointment status
const handleStatusChange = async (appointmentId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}/${appointmentId}`, {
      method: 'PATCH', // Assuming you need to use PATCH method to update the appointment status
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        booking_status: 'completed',
      }),
    });

    if (response.ok) {
      console.log(`Appointment with ID ${appointmentId} marked as completed`);
      setSuccessMessage(`Appointment with ID ${appointmentId} marked as completed`);

      // Update the appointment status in the state
      setAppointments(prevAppointments =>
        prevAppointments.map(appointment =>
          appointment.id === appointmentId
            ? { ...appointment, booking_status: 'completed' }
            : appointment
        )
      );
    } else {
      console.error(`Error marking appointment with ID ${appointmentId} as completed`);
    }
  } catch (error) {
    console.error('API request failed', error);
  }
};

      
    

  return (
    <div className="table-container">
    <h2>Booked Appointments</h2>

    {/* Navigation and Add buttons */}
    <div className="role-buttons">
    <Link to="/dashboard">
      <button className="back-button">
        <i className="fas fa-arrow-left"></i>Browse
      </button>
      </Link>
      <Link to="/admincarts">
        <button className="add-button">
          <i className="fas fa-plus"></i> Schedule
        </button>
      </Link>
    </div>

    {/* Display success message if any */}
    {successMessage && <div className="success-message">{successMessage}</div>}

    {/* Search input and button */}
    <div className="role-search-form">
      <input
        type="text"
        placeholder="Search ads..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button className="search-button" onClick={() => console.log('Search')}>
        <i className="fas fa-search"></i>
      </button>
    </div>

    {/* Records per page filter */}
    <div className="records-per-page">
      <label>Filter:</label>
      <select
        value={recordsPerPage}
        onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
      >
        {[5, 10, 15].map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>

<table className="appointments-custom-table">
  <thead>
    <tr>
      <th>No.</th>
      <th>Title</th>
      <th>Date</th>
      <th>Time</th>
      <th>Duration</th>
      <th>Status</th>
      <th>Room</th>
      <th>Therapist</th>
      <th>Client</th>
      <th>Successful</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {currentAppointments.map((appointment, index) => (
  <tr key={appointment.id}>
    <td>{index + 1}</td>
    <td>{appointment.cartpage.spaservice.service_name}</td>
    <td>{appointment.booking_date}</td>
    <td>{appointment.booking_time}</td>
    <td>{appointment.booking_duration} mins</td>
    <td>{appointment.booking_status}</td>
    <td>{appointment.room.room_name}</td>
    <td>{appointment.therapist.name}</td>
    <td>{appointment.cartpage.usercart.name}</td>
    <td>
      <input
        type="checkbox"
        checked={appointment.booking_status === 'completed'}
        onChange={() => handleStatusChange(appointment.id)}
      />
    </td>
      {/* Action buttons for edit, delete, and view */}
      <td className="action-btn-wrapper">
      {/* <Link to={`/view-ad}`}>
      <button className="action-button">
      <i className="fas fa-eye"></i>
      </button>
      </Link> */}
      <button className="action-button" onClick={() => handleEdit(appointment.id)}>
      <i className="fas fa-edit"></i>
      </button>
      <button className="action-button" onClick={() => handleDelete(appointment.id)}>
      <i className="fas fa-trash-alt"></i>
      </button>

      </td>

  </tr>
))}

</tbody>


</table>


{/* Pagination */}
<div className="pagination">
  <ul>
    {Array.from({ length: Math.ceil(filteredAppointments.length / recordsPerPage) }).map(
      (item, index) => (
        <li key={index}>
          <button onClick={() => paginate(index + 1)}>{index + 1}</button>
        </li>
      )
    )}
  </ul>
</div>
</div>
  );
};

export default AdsManagement;
