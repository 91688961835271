import React, { useEffect, useState } from 'react'; // Import React
import '../components/publicpage/styles.css'; // Import CSS file

import neclaLogo from '../components/publicpage/images/necla-logo.png'; // Import other resources as needed
// @ts-ignore
import bioVideo from '../components/publicpage/videos/biovideo.mp4'; // Import the bio video
// @ts-ignore
import bannerVideo from '../components/publicpage/videos/neclaad.mp4'; // Import the bio video
import themeBanner from '../components/publicpage/images/apriltheme.jpg'; // Import the theme banner image
import counterbackImage from '../components/publicpage/images/counterback.jpg'; // Import the counterback image
import image1 from '../components/publicpage/images/image1.png'; // Import image 1
import photo6 from '../components/publicpage/images/photo 6.png'; // Import photo 6
import spa1 from '../components/publicpage/images/spa1.png'; // Import spa1 image
import neclaPoster from '../components/publicpage/images/neclaposter.jpg'; // Import necla poster image
import facialMen2 from '../components/publicpage/images/gallery/facial men 2.png'; // Import facial men 2 image
import facialMen3 from '../components/publicpage/images/gallery/facial men 3.png'; // Import facial men 3 image
import facialMen4 from '../components/publicpage/images/gallery/facial men 4.png'; // Import facial men 4 image
import facialMen from '../components/publicpage/images/gallery/facial men.png'; // Import facial men image
import facialHer from '../components/publicpage/images/gallery/facialher.jpg'; // Import facial her image
import nail2 from '../components/publicpage/images/gallery/nail2.jpg'; // Import nail2 image
import nail1 from '../components/publicpage/images/gallery/nail1.jpg'; // Import nail1 image
import facialHe from '../components/publicpage/images/gallery/facialhe.jpg'; // Import facial he image
import facialHer1 from '../components/publicpage/images/gallery/facialher1.jpg'; // Import facial her 1 image
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

const SpaBarberPage = () => {
  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  const accessToken = localStorage.getItem('accessToken');
  const { user, logout } = useAuth();
  const [userDetails, setUserDetails] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleMenuToggle = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        if (navModal.style.display === "none" || navModal.style.display === "") {
          navModal.style.display = "flex";
        } else {
          navModal.style.display = "none";
        }
      }
    };

    const handleCloseModal = () => {
      const navModal = document.getElementById('navModal');
      if (navModal) {
        navModal.style.display = "none";
      }
    };

    const handleActivateLinks = () => {
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.addEventListener('click', () => {
          navLinks.forEach(innerLink => {
            innerLink.classList.remove('active');
          });
          link.classList.add('active');
        });
      });
    };

    // Add event listeners when component mounts
    const menuToggle = document.getElementById('menuToggle');
    const closeModal = document.getElementById('closeModal');

    if (menuToggle) {
      menuToggle.addEventListener('click', handleMenuToggle);
    }

    if (closeModal) {
      closeModal.addEventListener('click', handleCloseModal);
    }

    handleActivateLinks();

    // Cleanup
    return () => {
      if (menuToggle) {
        menuToggle.removeEventListener('click', handleMenuToggle);
      }
      if (closeModal) {
        closeModal.removeEventListener('click', handleCloseModal);
      }
      const navLinks = document.querySelectorAll('.nav-links a');
      navLinks.forEach(link => {
        link.removeEventListener('click', () => {});
      });
    };
  }, []); // Empty dependency array means this effect runs only once after initial render

  // Function to open the modal
  const openNavModal = () => {
    setIsNavModalOpen(true);
  };

  // Function to close the modal
  const closeNavModal = () => {
    setIsNavModalOpen(false);
  };



  useEffect(() => {
    const prevButton = document.querySelector('.prev');
    const nextButton = document.querySelector('.next');
    const slides = document.querySelectorAll('.slide');

    if (prevButton && nextButton) {
        prevButton.addEventListener('click', () => {
            clearInterval(slideInterval); // Clear interval when user interacts with the slider
            moveSlide(-1);
            startAutoSlide(); // Restart auto sliding after manual interaction
        });

        nextButton.addEventListener('click', () => {
            clearInterval(slideInterval); // Clear interval when user interacts with the slider
            moveSlide(1);
            startAutoSlide(); // Restart auto sliding after manual interaction
        });
    }

    let slideIndex = 0;
    let slideInterval: NodeJS.Timeout; // Variable to store the interval

    function startAutoSlide() {
        slideInterval = setInterval(() => {
            moveSlide(1);
        }, 3000); // Adjust the interval duration as needed
    }

    function moveSlide(direction: number) {
        slideIndex += direction;

        if (slideIndex < 0) {
            slideIndex = slides.length - 1;
        } else if (slideIndex >= slides.length) {
            slideIndex = 0;
        }

        slides.forEach(slide => {
            (slide as HTMLElement).style.display = 'none';
        });

        (slides[slideIndex] as HTMLElement).style.display = 'block';
    }

    startAutoSlide(); // Start auto sliding when component mounts

    return () => {
        clearInterval(slideInterval); // Clear interval when component unmounts
    };
}, []);
useEffect(() => {
  const slides = document.querySelectorAll('.testimonial-card');

  // Function to start the marquee animation
  function startMarqueeAnimation() {
    slides.forEach(slide => {
      slide.classList.add('marquee');
    });
  }

  // Function to stop the marquee animation
  function stopMarqueeAnimation() {
    slides.forEach(slide => {
      slide.classList.remove('marquee');
    });
  }

  // Start the marquee animation when component mounts
  startMarqueeAnimation();

  // Stop the marquee animation when component unmounts
  return () => {
    stopMarqueeAnimation();
  };
}, []);

useEffect(() => {
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.SINGLE_USER}/${user?.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.data && response.data.user) {
        setUserDetails(response.data.user);
      } else {
        console.error('Invalid user data in the server response');
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  if (user) {
    fetchUserDetails();
  }
}, [user, accessToken]);

const handleLogout = async () => {
  try {
    if (accessToken) {
      // Send a logout request to the server with the access token
      await axios.post(
        `${API_BASE_URL}${API_ENDPOINTS.LOGOUT}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      // Clear the user and access token from local storage
      logout();

      // Navigate to the login page
      navigate('/welcomepage', { replace: true });
    } else {
      console.error('Access token not found.');
    }
  } catch (error: any) {
    console.error('Logout failed:', error.message);
  }
};


    return (
    <>
      <div className="navbar">


        <div className="top-row">
          {/* Logo */}
          <div className="top-nav-pub-logo">
          <img src={neclaLogo} alt="Necla Logo" />
          </div>
          
          {/* Group of icons and toggle button */}
          <div className="right-group">
            {/* Bar icon button for smaller devices */}
            <button id="menuToggle" className="menu-toggle" onClick={openNavModal}>
            <i className="fas fa-bars"></i>
            </button>

            
            {/* Icons */}
            <div className="icons">
              <Link to="https://www.google.com/maps/dir//Necla+Spa+%26+Barbershop/@-1.1692746,36.8998078,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x182f419f275016ed:0xb467c20a7ae51900!2m2!1d36.9698484!2d-1.1692755"><span className="icon"><i className="fas fa-map-marker-alt"></i></span></Link>
 
              {/* <span className="icon"><i className="fas fa-user"></i></span> */}
              {/* <span className="icon"><i className="fas fa-shopping-cart"></i><span id="cart-count">0</span></span> */}
         
            <Link to="tel:+254 721 313219"><span className="icon"><i className="fas fa-mobile-alt"></i></span></Link>
     
            </div>
       

              
          </div>

          
          
         {/* Modal View for Nav Links on smaller devices */}
  
      {/* Modal View for Nav Links on smaller devices */}
      <div className="modal" id="navModal" style={{ display: isNavModalOpen ? "flex" : "none" }}>
        <div className="modal-content">
          {/* Button to close the modal */}
          <button id="closeModal" className="close-modal" onClick={closeNavModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="nav-links">
          {user && userDetails && (
            <>

          <Link to="/auth-adverts" className="know-more-btn">Adverts</Link>
          <Link to="/auth-services" className="know-more-btn">Services</Link>
          {/* <Link to="/maintenance" className="know-more-btn">Products</Link> */}
          <Link to="/about" className="know-more-btn">About</Link>
          <Link to="/contact" className="know-more-btn">Contact</Link>
          <Link to="/appointments" className="know-more-btn">Appointments</Link>
          <Link to="/cart" className="know-more-btn">Cart</Link> 
          <div className='icons'><FaSignOutAlt  onClick={handleLogout} /></div>


           
            
            </>

        )}
        {!user && (
          <>
         <Link to="/adverts" className="know-more-btn">Adverts</Link>
           <Link to="/services" className="know-more-btn">Services</Link>
           {/* <Link to="/maintenance" className="know-more-btn">Products</Link> */}
           <Link to="/about" className="know-more-btn">About</Link>
           <Link to="/contact" className="know-more-btn">Contact</Link>
           <Link to="/login" className="know-more-btn">Login</Link>
           <Link to="/register" className="know-more-btn">Register</Link>          
          </>


        )}
          </div>
        </div>
      </div>


          {/* Nav Links for larger screens */}
          <div className="nav-links">
          {user && userDetails && (
            <>

          <Link to="/auth-adverts" className="know-more-btn">Adverts</Link>
          <Link to="/auth-services" className="know-more-btn">Services</Link>
          {/* <Link to="/maintenance" className="know-more-btn">Products</Link> */}
          <Link to="/about" className="know-more-btn">About</Link>
          <Link to="/contact" className="know-more-btn">Contact</Link>
          <Link to="/appointments" className="know-more-btn">Appointments</Link>
          <Link to="/cart" className="know-more-btn">Cart</Link> 
          <div className='icons'><FaSignOutAlt  onClick={handleLogout} /></div>

           
            
            </>

        )}
        {!user && (
          <>
         <Link to="/adverts" className="know-more-btn">Adverts</Link>
           <Link to="/services" className="know-more-btn">Services</Link>
           {/* <Link to="/maintenance" className="know-more-btn">Products</Link> */}
           <Link to="/about" className="know-more-btn">About</Link>
           <Link to="/contact" className="know-more-btn">Contact</Link>
           <Link to="/login" className="know-more-btn">Login</Link>
           <Link to="/register" className="know-more-btn">Register</Link>             
          </>


        )}
          </div>
          
        </div>
      </div>

      <video id="localVideo" width="100%" height="100%" autoPlay loop muted>
        <source src={bannerVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <hr></hr> */}

      
      <section className="theme-month">
        <div className="theme-content">
          <div className="theme-banner">
            <h3 className="public-title">Theme of the Month</h3>
            <img src={themeBanner} alt="Theme of the Month Banner" />
          </div>
          <div className="theme-text">
            <h3 className="slogan public-title">Happy new month</h3>
            <p className="description">We as Necla Spa & Barbershop wish all our esteemed clients a Happy New and Holistic Month. 
              Welcome to Necla Spa & Barbershop for all you Holistic Spa & Barber concerns.
            </p>
              {/* Add the "Know More About Us" button */}
              {user && userDetails && (
            <>


            <p className="description">
            <Link className="view-button" to={`/auth-services`}>
                Schedule Appointment
            </Link>
            </p>
            
            </>

        )}
        {!user && (
          <>
          <p className="slogan public-title">            
              <Link to="/login" className="know-more-btn">click here to sign-in or register to book an appointment with us</Link>
              </p>

              <p className="slogan public-title">
              <Link to="/adverts" className="know-more-btn">Explore more in our advertisement section</Link>
              </p>       
          </>


        )}

         
          </div>
        </div>
      </section>

      <div className="image-slider">
        <div className="slide-window">
          <div className="slide"><img src={counterbackImage} alt="Image 1" /></div>
          <div className="slide"><img src={image1} alt="Image 2" /></div>
          <div className="slide"><img src={photo6} alt="Image 3" /></div>
          <div className="slide"><img src={spa1} alt="Image 2" /></div>
          <div className="slide"><img src={neclaPoster} alt="Image 3" /></div>
          {/* Add more slides as necessary */}
        </div>
        <button className="prev"><i className="fas fa-chevron-left"></i></button>
        <button className="next"><i className="fas fa-chevron-right"></i></button>
      </div>

      
      <section className="bio-section">
      <div className="bio-content">
        <div className="text-description">
          <h3 className="public-title">About Necla Spa & Barber</h3>
          <p>                 
            As you step into the necla spa, you will enter an oasis of peace, calm and tranquility.
            Whether you are escaping the pressure of everyday life or looking to a heightened relaxing experience, you will enter a world where time stands still Wellness and relaxation pervades.
            You will be in the hands of our friendly, highly qualified team of therapists where you choose your treatment.
            Whatever your goals, your experience awaits!
            <Link to="/about" className="know-more-btn">Know More About Us</Link>
          </p>      
        </div>
        <div className="video-banner">
          <video width="100%" height="100%" controls autoPlay loop muted>
            <source src={bioVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>

    {/* <hr></hr> */}


      <section className="gallery-section">
        <h3 className="public-title">Our Gallery</h3>
        <div className="gallery-grid">
          <img src={facialMen2} alt="Gallery Image 1" />
          <img src={facialMen3} alt="Gallery Image 2" />
          <img src={facialMen4} alt="Gallery Image 3" />
          <img src={facialMen} alt="Gallery Image 4" />
          <img src={facialHer} alt="Gallery Image 5" />
          <img src={nail2} alt="Gallery Image 6" />
          <img src={nail1} alt="Gallery Image 7" />
          <img src={facialHe} alt="Gallery Image 8" />
          <img src={facialHer1} alt="Gallery Image 9" />
          {/* Add more images as needed */}
        </div>
      </section>
{/* 
      <section className="testimonials">
        <h3 className="testimonial-title public-title">Testimonials</h3>
        <div className="slider">
          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>
          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>

          <div className="testimonial-card">
            <img className="client-profile" src={facialMen} alt="Client Name" />
            <h3 className="client-name">Client Name</h3>
            <div className="rating-stars">
              ★★★★☆
            </div>
            <p className="testimonial-description">Client's testimonial goes here...</p>
          </div>
        </div>

        {user && userDetails && (
            <>
      <div className="testimonial-form-container">
          <form action="path-to-your-backend-endpoint" method="post" encType="multipart/form-data">
            <button type="submit">Testify</button>
          </form>
        </div>
            
            </>

        )}
        {!user && (
          <>
       <div className="testimonial-form-container">
          <form action="path-to-your-backend-endpoint" method="post" encType="multipart/form-data">
            <button type="submit">Login To Testify</button>
          </form>
        </div>  
          </>


        )}
      </section> */}
      <footer>
        {/* <section className='contact-section'>
          <h3 className='testimonial-title public-title'>Contact Us</h3>
          <div className="testimonial-form-container">
          <form action="path-to-your-backend-endpoint" method="post" encType="multipart/form-data">
            <input type="file" name="profileImage" accept="image/*" />
            <input type="text" name="clientName" placeholder="Name" />
            <input type="email" name="clientEmail" placeholder="Email" />
            <input type="text" name="clientName" placeholder="Subject" />
            <div className="rating-input">
              <input type="radio" name="rating" value="1" id="star1" />
              <label htmlFor="star1">★</label>
              <input type="radio" name="rating" value="2" id="star2" />
              <label htmlFor="star2">★★</label>
            </div>
            <textarea name="testimonialDescription" rows={4} placeholder="Your Message" />
            <button type="submit">Submit</button>
          </form>
        </div>
        </section> */}
        <div className="footer-content">
          {/* Logo */}
          <div className="footer-logo">
            <img src={neclaLogo} alt="Necla Logo" />
          </div>
          
          {/* Social Media Icons */}
          <div className="footer-social">
            <a href="https://web.facebook.com/NeclaBeautyandSpa/?_rdc=1&_rdr"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/neclaspabarber"><i className="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/explore/locations/114366393540115/necla-spa-barbershop/"><i className="fab fa-instagram"></i></a>
            {/* <a href="#"><i className="fab fa-linkedin-in"></i></a> */}
          </div>
          
          {/* Contact Us Details */}
          <div className="footer-contact">
            <p>info@neclaspa.co.ke</p>
            <p>+254 721 313219</p>
            <p>Northpark Hub Mall 3rd floor Suit-T2, Ruiru Bypass, Kamakis Road, Nairobi, Kenya</p>
        </div>
        
          
          {/* Copyright Protection */}
          <div className="footer-copyright">
            <p>&copy; <span id="currentYear"></span> Necla Spa & Barber. All rights reserved.</p>
          </div>
        </div>
      </footer>
      <script src="publicpage/script.js"></script>
    </>
  );
};

export default SpaBarberPage;

