// Import necessary dependencies and styles
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/dashboard.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
// @ts-ignore
import bioVideo from '../components/publicpage/videos/biovideo.mp4'; // Import the bio video
// @ts-ignore

import neclaLogo from '../components/publicpage/images/necla-logo.png'; // Import other resources as needed


// Define the AboutContent component
const AboutContent: React.FC = () => {

  return (
    <div className="dashboard-content">
      
      <section className="bio-section">

          <div className="testimonial-form-container">
          <h3 className='testimonial-title public-title'>Contact Us</h3>
          <form action="path-to-your-backend-endpoint" method="post" encType="multipart/form-data">
            <input type="file" name="profileImage" accept="image/*" />
            <input type="text" name="clientName" placeholder="Name" />
            <input type="email" name="clientEmail" placeholder="Email" />
            <input type="text" name="clientName" placeholder="Subject" />
            <div className="rating-input">
              <input type="radio" name="rating" value="1" id="star1" />
              <label htmlFor="star1">★</label>
              <input type="radio" name="rating" value="2" id="star2" />
              <label htmlFor="star2">★★</label>
            </div>
            <textarea name="testimonialDescription" rows={4} placeholder="Your Message" />
            <button type="submit">Submit</button>
          </form>
        </div>
        </section>
      <hr></hr>
      <footer>
        
        <div className="footer-content">
          {/* Logo */}
          <div className="footer-logo">
            <img src={neclaLogo} alt="Necla Logo" />
          </div>
          
          {/* Social Media Icons */}
          <div className="footer-social">
            <a href="https://web.facebook.com/NeclaBeautyandSpa/?_rdc=1&_rdr"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com/neclaspabarber"><i className="fab fa-twitter"></i></a>
            <a href="https://www.instagram.com/explore/locations/114366393540115/necla-spa-barbershop/"><i className="fab fa-instagram"></i></a>
            {/* <a href="#"><i className="fab fa-linkedin-in"></i></a> */}
          </div>
          
          {/* Contact Us Details */}
          <div className="footer-contact">
            <p>info@neclaspa.co.ke</p>
            <p>+254 721 313219</p>
            <p>Northpark Hub Mall 3rd floor Suit-T2, Ruiru Bypass, Kamakis Road, Nairobi, Kenya</p>
        </div>
        
          
          {/* Copyright Protection */}
          <div className="footer-copyright">
            <p>&copy; <span id="currentYear"></span> Necla Spa & Barber. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutContent;
