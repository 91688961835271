// src/components/UserManagement/UserAccounts.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import '../../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig'; // Import API configuration
import { useAuth } from '../../AuthContext'; // Import the useAuth hook from AuthContext

// Define the interface for a Role
interface Role {
  id: number;
  role_name: string;
}

// Define the interface for a User
interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  phone: string;
  gender: string;
  roles: Role[];
}

// UserAccounts component
const UserAccounts: React.FC = () => {
  const navigate = useNavigate();
  // Use the useAuth hook to get access to the authentication context
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken'); // Get access token from localStorage

  // State variables
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // useEffect to fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.VIEW_USERS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
        } else {
          console.error('Error fetching users');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchUsers();
  }, [accessToken]);

  // Event handler for editing a user
  const handleEdit = (userId: number) => {
    console.log(`Editing user with ID ${userId}`);
    
    // Navigate to the "edit-user" path with the user ID as a parameter
    navigate(`/edit-user/${userId}`);
  };

  // Event handler for deleting a user
  const handleDelete = async (userId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.DELETE_USERS}/${userId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`User with ID ${userId} deleted successfully`);
        setSuccessMessage(`User with ID ${userId} deleted successfully`);
        window.location.reload(); // Refresh the page after a successful delete
      } else {
        console.error(`Error deleting user with ID ${userId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Filter users based on search query
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstRecord, indexOfLastRecord);

  // Event handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Event handler for pagination
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // JSX structure for the component
  return (
    <div className="table-container">
      {/* Header Section */}
      <h2>User Account Management</h2>
      
      {/* Buttons Section */}
      <div className="role-buttons">
        <Link to="/user-management">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i>Back
          </button>
        </Link>
        <Link to="/system-users/create-new">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>

      {/* Success Message Section */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Search Form Section */}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Records Per Page Section */}
      <div className="records-per-page">
        <label>filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Table Section */}
      <table className="custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Roles</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.gender}</td>
              <td>
                {user.roles.map((role) => role.role_name).join(', ')}
              </td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(user.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(user.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Section */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredUsers.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default UserAccounts;
