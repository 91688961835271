// src/components/UserManagement/RolePermissions.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';

interface Category {
  id: number;
  name: string;
  description: string;
}

const CategoriesManagement: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [categories, setCategories] = useState<Category[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCategories(data.categories);
        } else {
          console.error('Error fetching categories');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchCategories();
  }, [accessToken]);

  const handleEdit = (categoryId: number) => {
    console.log(`Editing category with ID ${categoryId}`);
    navigate(`/edit-category/${categoryId}`);
  };

  const handleDelete = async (categoryId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORIES}/${categoryId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Category with ID ${categoryId} deleted successfully`);
        setSuccessMessage(`Category with ID ${categoryId} deleted successfully`);
        window.location.reload();
      } else {
        console.error(`Error deleting category with ID ${categoryId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentCategories = filteredCategories.slice(indexOfFirstRecord, indexOfLastRecord);

  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Category Management</h2>

      <div className="role-buttons">
        <Link to="/advertisements">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </Link>
        <Link to="/add-category">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <table className="custom-table">
        <thead>
          <tr>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentCategories.map((category) => (
            <tr key={category.id}>
              <td>{category.name}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(category.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(category.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredCategories.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default CategoriesManagement;
