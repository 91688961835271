// Import necessary dependencies and components
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';

// Define the Room interface for room details
interface Room {
  id: number;
  room_name: string;
  room_description: string;
  room_images: Image[];
}

// Define the Image interface for image details
interface Image {
  id: number;
  image_url: string;
}

// RoomsManagement component definition
const RoomsManagement: React.FC = () => {
  // Hooks for navigation, authentication, and state management
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [rooms, setRooms] = useState<Room[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [selectedRoomImages, setSelectedRoomImages] = useState<Image[]>([]);
  const [showModal, setShowModal] = useState(false);

  // useEffect hook for fetching rooms data
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.VIEW_ROOMS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRooms(data.rooms);
        } else {
          console.error('Error fetching rooms');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchRooms();
  }, [accessToken]);

  // Handler for opening modal and setting selected room images
  const handleViewImages = (roomImages: Image[]) => {
    setSelectedRoomImages(roomImages);
    setShowModal(true);
  };

  // Handler for closing modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Handler for navigating to the edit page
  const handleEdit = (roomId: number) => {
    console.log(`Editing room with ID ${roomId}`);
    navigate(`/edit-room/${roomId}`);
  };

  // Handler for deleting a room
  const handleDelete = async (roomId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROOMS}/${roomId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Room with ID ${roomId} deleted successfully`);
        setSuccessMessage(`Room with ID ${roomId} deleted successfully`);
        window.location.reload();
      } else {
        console.error(`Error deleting room with ID ${roomId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Filter rooms based on search query
  const filteredRooms = rooms.filter(
    (room) =>
      room.room_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      room.room_description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRooms = filteredRooms.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Handler for paginating through records
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // JSX rendering of RoomsManagement component
  return (
    <div className="table-container">
      <h2>Saved Rooms</h2>

      {/* Navigation and Add buttons */}
      <div className="role-buttons">
        <Link to="/dashboard">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i>Explore Services
          </button>
        </Link>
        <Link to="/create-room">
          <button className="add-button">
            <i className="fas fa-plus"></i> Create Room
          </button>
        </Link>
      </div>

      {/* Display success message if any */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Search input and button */}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search rooms..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Records per page filter */}
      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Rooms table */}
      <table className="custom-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Room Title</th>
            <th>Room Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Map through rooms and display in table rows */}
          {currentRooms.map((room: Room, index: number) => (
            <tr key={room.id}>
              <td>{index + 1}</td>
              <td>{room.room_name}</td>
              <td>{room.room_description}</td>
              {/* Action buttons for edit, delete, and view */}
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(room.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(room.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
                <button className="action-button" onClick={() => handleViewImages(room.room_images)}>
                  <i className="fas fa-eye"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    {/* pagination */}
    <div className="pagination">
        <ul>
          {Array.from({ length: Math.ceil(filteredRooms.length / recordsPerPage) }).map(
            (item, index) => (
              <li key={index}>
                <button onClick={() => paginate(index + 1)}>{index + 1}</button>
              </li>
            )
          )}
        </ul>
      </div>

      {/* Modal for displaying room images */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h3>Room Images</h3>
            <div className="room-images">
              {selectedRoomImages.map((image: Image) => (
                <img key={image.id} src={image.image_url} alt="Room" className="room-image" />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Export the RoomsManagement component
export default RoomsManagement;

