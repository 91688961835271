// App.tsx
import React from 'react';
// import './components/publicpage/styles.css';
import './assets/css/styles.css';
import './assets/css/RolePermissions.css';
import './assets/css/AddRole.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import HomePageDashboard from './components/HomePageDashboard';
import AdminDashboard from './components/AdminDashboard';
import ServicesDashboard from './components/ServicesDashboard';
import AuthServicesDashboard from './components/AuthServicesDashboard';
import AdvertsDashboard from './components/AdvertsDashboard';
import AuthAdvertsDashboard from './components/AuthAdvertsDashboard';
import AdminCartsDashboard from './components/AdminCartsDashboard';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import UserManagementDashboard from './components/UserManagementDashboard';
import AdsManagementDashboard from './components/AdsManagementDashboard';
import RolesPermissionDashboard from './components/RolesPermissionDashboard';
import AdSubCategoryDashboard from './components/AdSubCategoryDashboard';
import AdCategoryDashboard from './components/AdCategoryDashboard';
import AdsDashboard from './components/AdsDashboard';
import ViewAdDashboard from './components/ViewAdDashboard';
import GuestViewAdDashboard from './components/GuestViewAdDashboard';
import EditAdDashboard from './components/EditAdDashboard';
import UserAccountsDashboard from './components/UserAccountsDashboard';
import AddRolePermissionDashboard from './components/AddRolePermissionDashboard';
import AddCategoryDashboard from './components/AddCategoryDashboard';
import AddAdDashboard from './components/AddAdDashboard';
import CreateAdDashboard from './components/CreateAdDashboard';
import CreateRoomDashboard from './components/CreateRoomDashboard';
import ListAdsDashboard from './components/ListAdsDashboard';
import RoomsDashboard from './components/RoomsDashboard';
import AddSubcategoryDashboard from './components/AddSubcategoryDashboard';
import AddUserAccountDashboard from './components/AddUserAccountDashboard';
import EditUserAccountDashboard from './components/EditUserAccountDashboard';
import EditRolePermissionDashboard from './components/EditRolePermissionDashboard';
import ServicesView from './components/ServicesViewDashboard';
import GuestServicesView from './components/GuestServicesViewDashboard';
import AddServiceDashboard from './components/AddServiceDashboard';
import CartView from './components/CartViewService';
import AppointmentsView from './components/AppointmentsView';
import BookingsView from './components/BookingsView';
import ScheduleView from './components/ScheduleViewDashboard';
import ReScheduleView from './components/ReScheduleViewDashboard';
import AddServiceCollectionDashboard from './components/AddServiceCollectionDashboard';
import AddCategoryCollection from './components/AddCategoryCollection';
import CustomerAccountsDashboard from './components/CustomerAccountsDashboard';
import AddCustomerAccountDashboard from './components/AddCustomerAccountDashboard';
import WelcomePage from './components/WelcomePage';
import AuthPageView from './components/AuthPageView';
import ContactPageView from './components/ContactPageView';
// import ProductPageView from './components/ProductPageView';
// import AuthProductPageView from './Authcomponents/AuthProductPageView';


const App: React.FC = () => {
  return (
    <Router basename="/necla-spa-barber">
      <AuthProvider>
        <Routes>
        <Route path="/" element={<WelcomePage />} />
          <Route path="/welcomepage" element={<WelcomePage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/homepage" element={<HomePageDashboard />} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/auth-adverts" element={<AuthAdvertsDashboard />} />
          <Route path="/services" element={<ServicesDashboard />} />
          <Route path="/auth-services" element={<AuthServicesDashboard />} />
          <Route path="/adverts" element={<AdvertsDashboard />} />
          <Route path="/admincarts" element={<AdminCartsDashboard />} />
          <Route path="/user-management/*" element={<UserManagementDashboard />} />
          <Route path="/advertisements/*" element={<AdsManagementDashboard />} />
          {/* <Route path="/role-permissions" element={<RolesPermissionDashboard />} /> */}
          <Route path="/ad-categories" element={<AdCategoryDashboard />} />
          <Route path="/sub-categories" element={<AdSubCategoryDashboard />} />
          {/* <Route path="/user-accounts" element={<UserAccountsDashboard />} /> */}
          {/* <Route path="/add-role" element={<AddRolePermissionDashboard />} /> */}
          <Route path="/add-category" element={<AddCategoryDashboard />} />
          <Route path="/add-ad" element={<AddAdDashboard />} />
          <Route path="/create-ad" element={<CreateAdDashboard />} />
          <Route path="/create-room" element={<CreateRoomDashboard />} />          
          <Route path="/list-ads" element={<ListAdsDashboard />} />
          <Route path="/rooms" element={<RoomsDashboard />} />
          <Route path="/add-subcategory" element={<AddSubcategoryDashboard />} />
          <Route path="/ads" element={<AdsDashboard />} />
          <Route path="/view-ad/:id" element={<ViewAdDashboard />} />
          <Route path="/guest-view-ad/:id" element={<GuestViewAdDashboard />} />
          <Route path="/edit-ad/:id" element={<EditAdDashboard />} />
          {/* <Route path="/add-user" element={<AddUserAccountDashboard />} /> */}
          <Route path="/edit-role/:roleId" element={<EditRolePermissionDashboard />} />
          <Route path="/edit-user/:userId" element={<EditUserAccountDashboard />} />
          <Route path="/services-view/:collection/:category" element={<ServicesView />} />
          <Route path="/guest-services/:collection/:category" element={<GuestServicesView />} />
          <Route path="/create-service/:category_id" element={<AddServiceDashboard />} />
          <Route path="/cart" element={<CartView />} />
          <Route path="/appointments" element={<AppointmentsView />} />
          <Route path="/bookings" element={<BookingsView />} />
          <Route path="/schedules/:service_id" element={<ScheduleView />} />
          <Route path="/schedules/:service_id/:cart_id" element={<ScheduleView />} />
          <Route path="/create-collection" element={<AddServiceCollectionDashboard />} />
          <Route path="/add-category/:collection_id" element={<AddCategoryCollection />} />
          <Route path="/roles-&-permissions/view-all" element={<RolesPermissionDashboard />} />
          <Route path="/roles-&-permissions/create-new" element={<AddRolePermissionDashboard />} />
          <Route path="/system-users/view-all" element={<UserAccountsDashboard />} />
          <Route path="/system-users/create-new" element={<AddUserAccountDashboard />} />
          <Route path="/customers/view-all" element={<CustomerAccountsDashboard />} />
          <Route path="/customers/create-new" element={<AddCustomerAccountDashboard />} />
          <Route path="/re-schedule/:appointment_id" element={<ReScheduleView />} />
          <Route path="/about" element={<AuthPageView />} />
          <Route path="/contact" element={<ContactPageView />} />
          {/* <Route path="/products" element={<ProductPageView />} />
          <Route path="/auth-products" element={<AuthProductPageView />} /> */}

          

        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
