import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import Calendar from './Calendar';
import { Col, Card, Row } from 'react-bootstrap';

interface Appointment {
  id: number;
  cart_id: number;
  room_id: number;
  booking_date: string;
  booking_time: string;
  booking_duration: number;
  booking_status: 'pending' | 'confirmed' | 'cancelled'; // Assuming booking_status can have specific values
  therapist_id: number;
  cartpage: CartPageDetails;
  room: RoomDetails;
  therapist: TherapistDetails;
}

interface CartPageDetails {
  user_id: number;
  service_id: number;
  status: 'pending' | 'confirmed' | 'cancelled'; // Assuming status can have specific values
  spaservice: SpaServiceDetails;
}

interface RoomDetails {
  room_name: string;
  room_description: string;
}

interface TherapistDetails {
  name: string;
  email: string;
  username: string;
  phone: string;
  gender: 'male' | 'female' | 'other'; // Assuming gender can have specific values
}

interface SpaServiceDetails {
  id: number;
  service_name: string;
  service_category: string; // Assuming service_category type
  service_description: string;
  service_price: string; // Assuming price is a string, change to number if applicable
  service_duration: number;
  created_at: string; // Assuming datetime format
  updated_at: string; // Assuming datetime format
}

const AdsManagement: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAppointments(data.appointments);
        } else {
          setErrorMessage('Error fetching appointments');
        }
      } catch (error) {
        console.error('API request failed', error);
        setErrorMessage('Error fetching appointments');
      }
    };

    fetchAppointments();
  }, [accessToken]);



  // Filter appointments for the logged-in user
// Filter appointments for the logged-in user
const filteredAppointments = appointments.filter(
    appointment =>
      user && appointment.cartpage.user_id === user.id &&
      (appointment.booking_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        appointment.booking_date.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAppointments = filteredAppointments.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };
  
  const handleReceipt = (appointmentId: number) => {
    // Logic for handling receipt
    console.log(`Generating receipt for appointment with ID ${appointmentId}`);
  };



const handleReschedule = (appointmentId: number) => {
  // navigate(`/re-schedule/${appointmentId}`);
};

  


  // Handler for paginating through records
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <Row>

      <Col xs={12} md={12} lg={6}>
          <Card>
            <Card.Body>
              <h1>Appointments</h1>
              <Calendar />
            </Card.Body>
          </Card>
        </Col>



      </Row>



      {/* Navigation and Add buttons */}
      <div className="role-buttons">
        <Link to="/dashboard">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i>Browse
          </button>
        </Link>
        <Link to="/cart">
          <button className="add-button">
            <i className="fas fa-plus"></i> Schedule
          </button>
        </Link>
      </div>

      <h2>Booked Appointments</h2>

      {/* Display success message if any */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Search input and button */}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search ads..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Records per page filter */}
      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Appointments table */}
 
<table className="appointment-custom-table">
  <thead>
    <tr>
      <th>No.</th>
      <th>Title</th>
      <th>Date</th>
      <th>Time</th>
      <th>Duration</th>
      <th>Status</th>
      <th>Room</th>
      <th>Therapist</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
  {currentAppointments.map((appointment, index) => (
  <tr key={appointment.id}>
    <td>{index + 1}</td>
    {/* Display service title */}
    <td>{appointment.cartpage.spaservice.service_name}</td>
    <td>{appointment.booking_date}</td>
    <td>{appointment.booking_time}</td>
    <td>{appointment.booking_duration} mins</td>
    <td>{appointment.booking_status}</td>
    <td>{appointment.room.room_name}</td>
    <td>{appointment.therapist.name}</td>
    <td className="action-column">
    <div className="d-flex service-action-btns">
    <button className="action-button" onClick={() => handleReschedule(appointment.id)}>Re-schedule</button>
    {/* <button className="action-button" onClick={() => handleReceipt(appointment.id)}>Receipt</button> */}
    </div>
    </td>
  </tr>
))}

  </tbody>
</table>


{/* Pagination */}
<div className="pagination">
  <ul>
    {Array.from({ length: Math.ceil(filteredAppointments.length / recordsPerPage) }).map(
      (item, index) => (
        <li key={index}>
          <button onClick={() => paginate(index + 1)}>{index + 1}</button>
        </li>
      )
    )}
  </ul>
</div>
</div>
  );
};

export default AdsManagement;
