
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import { useAuth } from '../../AuthContext';
// Import the YouTube type from 'react-youtube'
import YouTube from 'react-youtube';

// Define the YouTubeOptions type for YouTube component customization
type YouTubeOptions = {
  width?: string | number;
  height?: string | number;
  videoId?: string;
  playerVars?: {
    [key: string]: any;
  };
  [key: string]: any;
};

// Define the Ad interface for ad details
interface Ad {
  id: number;
  title: string;
  description: string;
  image_url?: string | null;
  video_url?: string | null;
  category_id: number;
  subcategory_id: number;
  user_id: number;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  ad_type: string;
  images: Image[];
  is_active: number;
  phone: string;
}

// Define the Image interface for images details
interface Image {
    id: number;
    image_url: string;
  }

// Extend Ad interface to create AdWithDetails interface
interface AdWithDetails extends Ad {
  is_active: number;
  ad_type: string;
  category_name: string;
  subcategory_name: string;
  user_full_name: string;
  images: Image[];
  phone: string;
}

// AdsManagement component definition
const AdsManagement: React.FC = () => {
  // Hooks for navigation, authentication, and state management
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [ads, setAds] = useState<Ad[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // useEffect hook for fetching user ads data
// useEffect hook for fetching all ads data
useEffect(() => {
  const fetchAds = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.PUBLICADS}`, {});

      if (response.ok) {
        const data = await response.json();
        setAds(data.ads);
      } else {
        console.error('Error fetching ads');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  fetchAds();
}, []);


  // Handler for navigating to the edit page
  const handleEdit = (adId: number) => {
    console.log(`Editing ad with ID ${adId}`);
    navigate(`/edit-ad/${adId}`);
  };

  // Handler for deleting an ad
  const handleDelete = async (adId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${adId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Ad with ID ${adId} deleted successfully`);
        setSuccessMessage(`Ad with ID ${adId} deleted successfully`);
        window.location.reload();
      } else {
        console.error(`Error deleting ad with ID ${adId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Filter ads based on search query
  const filteredAds = ads.filter(
    (ad) =>
      ad.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ad.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentAds = filteredAds.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Extract YouTube video ID from the video URL
  const getYouTubeVideoId = (url: string): string | null => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? match[1] : null;
  };

// Handler for updating the status of ads
// const handlePublish = async (adId: number, isPublished: boolean) => {
//   try {
//     const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.UPDATEADS}/${adId}`, {
//       method: 'POST', // Change the method to POST
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       },
//       body: JSON.stringify({ is_active: isPublished ? 1 : 0 }), // Update the is_active field based on checkbox status
//     });

//     if (response.ok) {
//       console.log(`Ad with ID ${adId} ${isPublished ? 'published' : 'unpublished'} successfully`);
//       setSuccessMessage(`Ad with ID ${adId} ${isPublished ? 'published' : 'unpublished'} successfully`);
//       // Refresh ads data after updating status
//       fetchUserAds();
//     } else {
//       console.error(`Error updating status for ad with ID ${adId}`);
//     }
//   } catch (error) {
//     console.error('API request failed', error);
//   }
// };


  // Handler for paginating through records
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="table-container">
      <h2>Your Saved Adverts</h2>

      {/* Navigation and Add buttons */}
      <div className="role-buttons">
      <Link to="/dashboard">
        <button className="back-button">
          <i className="fas fa-arrow-left"></i> All Adverts
        </button>
        </Link>
        <Link to="/add-ad">
          <button className="add-button">
            <i className="fas fa-plus"></i> Create Advert
          </button>
        </Link>
      </div>

      {/* Display success message if any */}
      {successMessage && <div className="success-message">{successMessage}</div>}

      {/* Search input and button */}
      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search ads..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* Records per page filter */}
      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

  {/* Ads table */}
  <table className="custom-table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Title</th>
            <th>Type</th>
            <th>Category</th>
            <th>Cluster</th>
            {/* <th>Status</th> */}
            {/* <th>Publish</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Map through ads and display in table rows */}
          {currentAds.map((ad: Ad, index: number) => {
            const adWithDetails = ad as AdWithDetails;
            return (
              <tr key={adWithDetails.id}>
               <td>{index + 1}</td>
                <td>{adWithDetails.title}</td>
                <td>{adWithDetails.ad_type}</td>
                <td>{adWithDetails.category_name}</td>
                <td>{adWithDetails.subcategory_name}</td>
                {/* Conditional rendering for the "Status" column */}
                {/* <td style={{ color: adWithDetails.is_active ? 'green' : 'red' }}>
                  {adWithDetails.is_active ? 'Published' : 'Unpublished'}
                </td> */}

                {/* <td>
                  <input
                    type="checkbox"
                    checked={adWithDetails.is_active === 1}
                    onChange={(e) => handlePublish(adWithDetails.id, e.target.checked)}
                  />
                </td> */}
                {/* Action buttons for edit, delete, and view */}
                <td className="action-btn-wrapper">
                <Link to={`/view-ad/${adWithDetails.id}`}>
                    <button className="action-button">
                      <i className="fas fa-eye"></i>
                    </button>
                  </Link>
                  <button className="action-button" onClick={() => handleEdit(adWithDetails.id)}>
                    <i className="fas fa-edit"></i>
                  </button>
                  <button className="action-button" onClick={() => handleDelete(adWithDetails.id)}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <ul>
          {Array.from({ length: Math.ceil(filteredAds.length / recordsPerPage) }).map(
            (item, index) => (
              <li key={index}>
                <button onClick={() => paginate(index + 1)}>{index + 1}</button>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};
export default AdsManagement;
function fetchUserAds() {
  throw new Error('Function not implemented.');
}

