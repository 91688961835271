// src/components/UserManagement/RolePermissions.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import '../../assets/css/RolePermissions.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig'; // Import API configuration
import { useAuth } from '../../AuthContext'; // Import the useAuth hook from AuthContext

// Define the interface for a Role
interface Role {
  id: number;
  role_name: string;
}

// RolePermissions component
const RolePermissions: React.FC = () => {
 const navigate = useNavigate();
  // Use the useAuth hook to get access to the authentication context
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken'); // Get access token from localStorage

  // State variables
  const [roles, setRoles] = useState<Role[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // useEffect to fetch roles when the component mounts
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROLES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setRoles(data.roles);
        } else {
          console.error('Error fetching roles');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchRoles();
  }, [accessToken]);

  // Event handler for editing a role
  const handleEdit = (roleId: number) => {
    console.log(`Editing role with ID ${roleId}`);
    
    // Navigate to the "edit-role" path with the role ID as a parameter
    navigate(`/edit-role/${roleId}`);
  };

  // Event handler for deleting a role
  const handleDelete = async (roleId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROLES}/${roleId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        console.log(`Role with ID ${roleId} deleted successfully`);
        setSuccessMessage(`Role with ID ${roleId} deleted successfully`);
        window.location.reload(); // Refresh the page after a successful delete
      } else {
        console.error(`Error deleting role with ID ${roleId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Filter roles based on search query
  const filteredRoles = roles.filter((role) =>
    role.role_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRoles = filteredRoles.slice(indexOfFirstRecord, indexOfLastRecord);

  // Event handler for changing records per page
  const handleRecordsPerPageChange = (value: number) => {
    setRecordsPerPage(value);
    setCurrentPage(1);
  };

  // Event handler for pagination
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // JSX structure for the component
  return (
    <div className="table-container">
      <h2>Role and Permission Management</h2>

      <div className="role-buttons">
        <Link to="/user-management">
          <button className="back-button">
            <i className="fas fa-arrow-left"></i> Back
          </button>
        </Link>
        <Link to="/roles-&-permissions/create-new">
          <button className="add-button">
            <i className="fas fa-plus"></i> Add
          </button>
        </Link>
      </div>

      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="role-search-form">
        <input
          type="text"
          placeholder="Search roles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="search-button" onClick={() => console.log('Search')}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      <div className="records-per-page">
        <label>Filter:</label>
        <select
          value={recordsPerPage}
          onChange={(e) => handleRecordsPerPageChange(Number(e.target.value))}
        >
          {[5, 10, 15].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <table className="custom-table">
        <thead>
          <tr>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRoles.map((role) => (
            <tr key={role.id}>
              <td>{role.role_name}</td>
              <td className="action-btn-wrapper">
                <button className="action-button" onClick={() => handleEdit(role.id)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button className="action-button" onClick={() => handleDelete(role.id)}>
                  <i className="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredRoles.length / recordsPerPage) }).map(
          (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)}>
              {index + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default RolePermissions;
