// Import necessary dependencies and styles
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/createAdStyles.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAuth } from '../../AuthContext';

// Define the CreateAd component
const CreateAd = () => {
  // Retrieve the access token from local storage
  const accessToken = localStorage.getItem('accessToken');
  // Hook for navigating between pages
  const navigate = useNavigate();
  // Access user information from AuthContext
  const { user } = useAuth() || { user: null }; // Use useAuth and provide a default value
  // Get the ad ID from the route parameters
  const { id } = useParams();

  // State to manage form data
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    images: [] as File[],
    video_url: '',
    category_id: 0,
    subcategory_id: 0,
    user_id: user?.id || 0, // Use optional chaining and provide a default value
    is_active: 0, // Default to inactive
    ad_type: 'product',
    price: 0,
  });

  // States to store categories, subcategories, and users fetched from the API
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [existingImages, setExistingImages] = useState<{ id: number; image_url: string }[]>([]);


  // useEffect hook to fetch data (categories, subcategories, and users) when the component mounts
  useEffect(() => {
    // Fetch ad data based on the provided ID
    const fetchAdData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ADS}/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const adData = data.ad;

          // Update the form data with fetched ad details
          setFormData({
            title: adData.title,
            description: adData.description,
            images: [], // Initialize with an empty array as images are fetched separately
            video_url: adData.video_url,
            category_id: adData.category_id,
            subcategory_id: adData.subcategory_id,
            user_id: adData.user_id,
            is_active: adData.is_active,
            ad_type: adData.ad_type,
            price: adData.price,
          });

          // Update existing images state
        setExistingImages(adData.images);

          // Fetch categories based on the fetched ad's category ID
          handleCategoryChange(adData.category_id);
        } else {
          console.error('Error fetching ad data');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Fetch categories from the API and update state
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CATEGORIES}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCategories(data.categories);
        } else {
          console.error('Error fetching categories');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Fetch users from the API and update state
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.VIEW_USERS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
        } else {
          console.error('Error fetching users');
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    // Call the fetch functions
    fetchAdData();
    fetchCategories();
    fetchUsers();
  }, [accessToken, id, user?.id]); // Include user.id in the dependency array

  // Handle category change
  const handleCategoryChange = async (selectedCategoryId: number) => {
    // Fetch subcategories based on the selected category
    try {
      const response = await fetch(
        `${API_BASE_URL}${API_ENDPOINTS.SUBCATEGORIES}?category_id=${selectedCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSubcategories(data.subcategories);
      } else {
        console.error('Error fetching subcategories for the selected category');
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Create a new FormData object to handle file uploads
     // Create a new FormData object to handle file uploads
const formDataToSend = new FormData();
formDataToSend.append('id', String(id));
formDataToSend.append('title', formData.title);
formDataToSend.append('description', formData.description);

// Convert the number to a string before appending
formDataToSend.append('price', String(formData.price));

formData.images.forEach((image, index) => {
  formDataToSend.append(`images[${index}]`, image);
});

formDataToSend.append('video_url', formData.video_url);
formDataToSend.append('category_id', String(formData.category_id));
formDataToSend.append('subcategory_id', String(formData.subcategory_id));
formDataToSend.append('user_id', String(formData.user_id));
formDataToSend.append('is_active', String(formData.is_active));
formDataToSend.append('ad_type', formData.ad_type);

// Send a PUT request to the API to update the ad
const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.UPDATEADS}`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
  body: formDataToSend,
});


      if (response.ok) {
        // If the request is successful, log the data and navigate to the ads view
        const adData = await response.json();
        console.log('Ad updated successfully', adData);
        navigate('/list-ads');
      } else {
        // If there is an error, log it
        console.error('Error updating ad');
      }
    } catch (error) {
      // If there is a general error, log it
      console.error('API request failed', error);
    }
  };

  // Handle image file change
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    setFormData({ ...formData, images: files });
  };

  // Add this function to handle removing an image from the form data
const handleRemoveImage = (indexToRemove: number) => {
  const updatedImages = formData.images.filter((_, index) => index !== indexToRemove);
  setFormData({ ...formData, images: updatedImages });
};

 // Handle image deletion
 const handleDeleteImage = async (imageId: number) => {
    try {
      // Send a DELETE request to the API to delete the image
      const response = await fetch(`${API_BASE_URL}/api/deleteimage/${imageId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        // If the deletion is successful, update the existingImages state
        setExistingImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
      } else {
        // If there is an error, log it
        console.error('Error deleting image');
      }
    } catch (error) {
      // If there is a general error, log it
      console.error('API request failed', error);
    }
  };


  return (
    <div className="add-role-container create-ad-container">
      <h2>Modify Your Advert Here</h2>
      <Link to="/list-ads" className="role-back-button">
        Saved Adverts
        <i className="fas fa-arrow-right"></i>
      </Link>
      <form className="add-role-form create-ad-form" onSubmit={handleSubmit}>
        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Title input */}
          <label className="create-ad-label" htmlFor="adTitle">
            Write Your Title:
          </label>
          <input
            type="text"
            id="adTitle"
            className="create-ad-input"
            placeholder="Ad title"
            value={formData.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Description textarea with ReactQuill */}
          <label className="create-ad-label" htmlFor="adDescription">
            Describe Your Advert:
          </label>
          <ReactQuill
            value={formData.description}
            onChange={(value: any) => setFormData({ ...formData, description: value })}
          />
        </div>

        <div className="form-section">
        {/* Ad Category dropdown */}
<div className="create-add-form-column">
  <label className="create-ad-label" htmlFor="adCategory">
    Categorise Your Advert:
  </label>
  <select
    id="adCategory"
    className="create-ad-select"
    value={formData.category_id}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
      const selectedCategoryId = Number(e.target.value);
      setFormData({ ...formData, category_id: selectedCategoryId });
      handleCategoryChange(selectedCategoryId);
    }}
  >
    <option value={0}>Select Category</option>
    {categories.map((category: any) => (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    ))}
  </select>
</div>

{/* Ad Subcategory dropdown */}
<div className="create-add-form-column">
  <label className="create-ad-label" htmlFor="adSubcategory">
    Cluster Your Categorised Advert:
  </label>
  <select
    id="adSubcategory"
    className="create-ad-select"
    value={formData.subcategory_id}
    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
      setFormData({ ...formData, subcategory_id: Number(e.target.value) })
    }
  >
    <option value={0}>Select Subcategory</option>
    {subcategories.map((subcategory: any) => (
      <option key={subcategory.id} value={subcategory.id}>
        {subcategory.name}
      </option>
    ))}
  </select>
</div>

        </div>
        <div className="form-section">
          {/* Ad Type dropdown */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adType">
              What Type is Your Advert?
            </label>
            <select
              id="adType"
              className="create-ad-select"
              value={formData.ad_type}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setFormData({ ...formData, ad_type: e.target.value })
              }
            >
              <option>Select Type</option>
              <option value="product">Product</option>
              <option value="service">Service</option>
              <option value="info">Info</option>
            </select>
          </div>

          {/* Price input */}
          <div className="create-add-form-column">
            <label className="create-ad-label" htmlFor="adPrice">
              Does it have a price?(Optional):
            </label>
            <input
            type="text"
            id="adPrice"
            className="create-ad-input"
            placeholder="Ad price"
            value={formData.price.toString()} // Convert the number to a string for input value
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, price: parseFloat(e.target.value) || 0 })
            }
            />

          </div>
        </div>

        <div className="create-add-form-column create-add-form-column-full-width">
          {/* Ad Images file input (multiple) */}
          <label className="create-ad-label" htmlFor="adImages">
            Upload Images related to your advert(you can upload multiple):
          </label>
          <input
            type="file"
            id="adImages"
            className="create-ad-input"
            accept="image/*"
            onChange={handleImageChange}
            multiple
          />
          {/* Display the selected images */}
  {formData.images.length > 0 && (
    <div>
      <label className="create-ad-label">Selected Images:</label>
      <table className="image-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {formData.images.map((image, index) => (
            <tr key={index}>
              <td>
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Selected Ad Image ${index + 1}`}
                  style={{ maxWidth: '100%', maxHeight: '100px' }}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="remove-image-button"
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}

 {/* Images */}
 <h5 className='images-title'>Featured Posters</h5>
<div className="images-container">
  {/* Featured Posters */}
  <h5 className='images-title'>Featured Posters</h5>
        <table className="image-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {existingImages.map((image) => (
              <tr key={image.id}>
                <td>
                  <img
                    src={image.image_url}
                    alt={`Existing Ad Image ${image.id}`}
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(image.id)}
                    className="delete-image-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
</div>
        </div>

        {/* Ad Video URL input */}
        <div className="create-add-form-column create-add-form-column-full-width">
          <label className="create-ad-label" htmlFor="adVideoUrl">
            Link YouTube Video URL(to enhance your advertised content):
          </label>
          <input
            type="text"
            id="adVideoUrl"
            className="create-ad-input"
            placeholder="Ad video URL"
            value={formData.video_url}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, video_url: e.target.value })
            }
          />
        </div>

        <div className="form-section">
          {/* Ad User input (disabled) */}
          <div className="create-add-form-column">
            {/* <label className="create-ad-label" htmlFor="adUserId">
              User ID:
            </label> */}
            <input
              id="adUserId"
              className="create-ad-input"
              type="tex"
              value={formData.user_id}
              readOnly
              disabled
            />
          </div>

          {/* Is Active input (disabled) */}
          <div className="create-add-form-column">
            {/* <label className="create-ad-label" htmlFor="adIsActive">
              Is Active:
            </label> */}
            <input
              id="adIsActive"
              className="create-ad-input"
              type="hidden"
              value={formData.is_active === 1 ? 'Active' : 'Inactive'}
              readOnly
              disabled
            />
          </div>
        </div>

        {/* Submit button */}
        <button type="submit" className="create-ad-submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateAd;
