import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { format, startOfMonth, endOfMonth, addDays, getDay } from 'date-fns';
import '../assets/calendar.css'; // Import CSS file
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';

const Calendar: React.FC = () => {
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [daysInMonth, setDaysInMonth] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number>(currentDate.getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(currentDate.getFullYear());
  const [bookedAppointments, setBookedAppointments] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchBookedAppointments = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.APPOINTMENTS}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBookedAppointments(data.appointments);
        } else {
          console.error('Failed to fetch booked appointments:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching booked appointments:', error);
      }
    };
  
    if (accessToken) {
      fetchBookedAppointments();
    }
  }, [accessToken]);

  useEffect(() => {
    const days = getDaysInMonth(selectedYear, selectedMonth);
    setDaysInMonth(days);
  }, [selectedYear, selectedMonth]);

  const getDaysInMonth = (year: number, month: number): Date[] => {
    const startDate = startOfMonth(new Date(year, month));
    const endDate = endOfMonth(new Date(year, month));
    const daysArray: Date[] = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      daysArray.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
    return daysArray;
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(event.target.value));
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };

  const isBookedDate = (date: Date): boolean => {
    const dateStr = format(date, 'yyyy-MM-dd');
    return bookedAppointments.some(appointment => appointment.booking_date === dateStr);
  };

  const handleClickDate = (date: Date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderTimeSlots = () => {
    if (!selectedDate) return null;

    const timeSlots: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute === 0 ? '00' : minute}`;
        timeSlots.push(formattedTime);
      }
    }

    const dateStr = format(selectedDate, 'yyyy-MM-dd');
    const bookedTimes = bookedAppointments
      .filter(appointment => appointment.booking_date === dateStr)
      .map(appointment => appointment.booking_time);

    return (
      <tbody>
        {timeSlots.map((timeSlot, index) => (
          <tr key={index}>
            <td>{timeSlot}</td>
            <td>{bookedTimes.includes(timeSlot) ? 'Booked' : 'Available'}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="calendar">
      <div className="header">
        <div className="dropdowns">
          <select className="month-dropdown" value={selectedMonth} onChange={handleMonthChange}>
            {Array.from({ length: 12 }).map((_, index) => (
              <option key={index} value={index}>
                {new Date(currentDate.getFullYear(), index, 1).toLocaleString('default', { month: 'long' })}
              </option>
            ))}
          </select>
          <select className="year-dropdown" value={selectedYear} onChange={handleYearChange}>
            {Array.from({ length: 10 }, (_, index) => (
              <option key={index} value={currentDate.getFullYear() - 5 + index}>
                {currentDate.getFullYear() - 5 + index}
              </option>
            ))}
          </select>
        </div>
        <div className="current-time">
          Current Time: {currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
        </div>
      </div>

      <h2 className="month">{currentDate.toLocaleString('default', { month: 'long' })}</h2>

      <div className="weekdays">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((weekday, index) => (
          <div key={index} className="weekday">
            {weekday}
          </div>
        ))}
      </div>

      <div className="days">
        {Array.from({ length: getDay(new Date(selectedYear, selectedMonth, 1)) }).map((_, index) => (
          <div key={`empty-${index}`} className="day"></div>
        ))}
        {daysInMonth.map((date, index) => (
          <div key={index} className={`day ${isBookedDate(date) ? 'booked' : ''}`} onClick={() => handleClickDate(date)}>
            {date.getDate()}
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              {renderTimeSlots()}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
