import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
// import '../../assets/AddRole.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_ENDPOINTS } from '../../my-api/apiConfig';

// Define types for better type checking
type Permissions = {
  createRole: boolean;
  readRole: boolean;
  updateRole: boolean;
  deleteRole: boolean;
  createUser: boolean;
  readUser: boolean;
  updateUser: boolean;
  deleteUser: boolean;
  createAdvertisement: boolean;
  readAdvertisement: boolean;
  updateAdvertisement: boolean;
  deleteAdvertisement: boolean;
  // Additional entities
  createSystemUser: boolean;
  readSystemUser: boolean;
  updateSystemUser: boolean;
  deleteSystemUser: boolean;
  createCustomer: boolean;
  readCustomer: boolean;
  updateCustomer: boolean;
  deleteCustomer: boolean;
  createCollection: boolean;
  readCollection: boolean;
  updateCollection: boolean;
  deleteCollection: boolean;
  createCategory: boolean;
  readCategory: boolean;
  updateCategory: boolean;
  deleteCategory: boolean;
  createService: boolean;
  readService: boolean;
  updateService: boolean;
  deleteService: boolean;
  createAppointment: boolean;
  readAppointment: boolean;
  updateAppointment: boolean;
  deleteAppointment: boolean;
  createAnalytics: boolean;
  readAnalytics: boolean;
  updateAnalytics: boolean;
  deleteAnalytics: boolean;
};

const EditRole: React.FC = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { roleId } = useParams();

  const [formData, setFormData] = useState({
    roleName: '',
    permissions: {
      createRole: false,
      readRole: false,
      updateRole: false,
      deleteRole: false,
      createUser: false,
      readUser: false,
      updateUser: false,
      deleteUser: false,
      createAdvertisement: false,
      readAdvertisement: false,
      updateAdvertisement: false,
      deleteAdvertisement: false,
      // Additional entities
      createSystemUser: false,
      readSystemUser: false,
      updateSystemUser: false,
      deleteSystemUser: false,
      createCustomer: false,
      readCustomer: false,
      updateCustomer: false,
      deleteCustomer: false,
      createCollection: false,
      readCollection: false,
      updateCollection: false,
      deleteCollection: false,
      createCategory: false,
      readCategory: false,
      updateCategory: false,
      deleteCategory: false,
      createService: false,
      readService: false,
      updateService: false,
      deleteService: false,
      createAppointment: false,
      readAppointment: false,
      updateAppointment: false,
      deleteAppointment: false,
      createAnalytics: false,
      readAnalytics: false,
      updateAnalytics: false,
      deleteAnalytics: false,
    },
  });

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/roles/${roleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const roleData = await response.json();

          // Check if permissions exist before processing
          if (roleData.role && roleData.role.permissions) {
            const permissionsArray: string[] = roleData.role.permissions;

            // Populate form data with fetched role data
            setFormData({
              roleName: roleData.role.role_name,
              permissions: permissionsArray.reduce(
                (permissionsObject: Permissions, permission: string) => {
                  permissionsObject[permission as keyof Permissions] = true;
                  return permissionsObject;
                },
                {
                  createRole: false,
                  readRole: false,
                  updateRole: false,
                  deleteRole: false,
                  createUser: false,
                  readUser: false,
                  updateUser: false,
                  deleteUser: false,
                  createAdvertisement: false,
                  readAdvertisement: false,
                  updateAdvertisement: false,
                  deleteAdvertisement: false,
                  createSystemUser: false,
                  readSystemUser: false,
                  updateSystemUser: false,
                  deleteSystemUser: false,
                  createCustomer: false,
                  readCustomer: false,
                  updateCustomer: false,
                  deleteCustomer: false,
                  createCollection: false,
                  readCollection: false,
                  updateCollection: false,
                  deleteCollection: false,
                  createCategory: false,
                  readCategory: false,
                  updateCategory: false,
                  deleteCategory: false,
                  createService: false,
                  readService: false,
                  updateService: false,
                  deleteService: false,
                  createAppointment: false,
                  readAppointment: false,
                  updateAppointment: false,
                  deleteAppointment: false,
                  createAnalytics: false,
                  readAnalytics: false,
                  updateAnalytics: false,
                  deleteAnalytics: false,
                }
              ),
            });
          } else {
            console.error(`Permissions field is missing in the role data with ID ${roleId}`);
          }
        } else {
          console.error(`Error fetching role with ID ${roleId}`);
        }
      } catch (error) {
        console.error('API request failed', error);
      }
    };

    fetchRoleData();
  }, [roleId, accessToken]);

  const handleCheckboxChange = (entity: string, permission: keyof Permissions) => {
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData.permissions,
        [permission]: !prevData.permissions[permission],
      },
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const selectedPermissions = Object.keys(formData.permissions).filter(
      (permission) => formData.permissions[permission as keyof Permissions]
    );

    try {
      const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.ROLES}/${roleId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          role_name: formData.roleName,
          permissions: selectedPermissions,
        }),
      });

      if (response.ok) {
        console.log(`Role with ID ${roleId} updated successfully`);
        navigate('/roles-&-permissions/view-all');
      } else {
        console.error(`Error updating role with ID ${roleId}`);
      }
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="add-role-container">
      <h2>Edit Role</h2>
      <Link to="/roles-&-permissions/view-all" className="role-back-button">
        <i className="fas fa-arrow-left"></i> Back
      </Link>
      <form className="add-role-form" onSubmit={handleSubmit}>
        <label htmlFor="roleName">Role Name:</label>
        <input
          type="text"
          id="roleName"
          placeholder="role name"
          value={formData.roleName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFormData({ ...formData, roleName: e.target.value })
          }
        />

        <h3>Permissions:</h3>
        <table>
          <thead>
            <tr>
              <th>Entity</th>
              <th>Create</th>
              <th>Read</th>
              <th>Update</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Role & Permissions</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createRole}
                  onChange={() => handleCheckboxChange('Role', 'createRole')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readRole}
                  onChange={() => handleCheckboxChange('Role', 'readRole')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateRole}
                  onChange={() => handleCheckboxChange('Role', 'updateRole')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteRole}
                  onChange={() => handleCheckboxChange('Role', 'deleteRole')}
                />
              </td>
            </tr>

            {/* <tr>
              <td>User</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createUser}
                  onChange={() => handleCheckboxChange('User', 'createUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readUser}
                  onChange={() => handleCheckboxChange('User', 'readUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateUser}
                  onChange={() => handleCheckboxChange('User', 'updateUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteUser}
                  onChange={() => handleCheckboxChange('User', 'deleteUser')}
                />
              </td>
            </tr> */}

            <tr>
              <td>Advertisement</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createAdvertisement}
                  onChange={() => handleCheckboxChange('Advertisement', 'createAdvertisement')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readAdvertisement}
                  onChange={() => handleCheckboxChange('Advertisement', 'readAdvertisement')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateAdvertisement}
                  onChange={() => handleCheckboxChange('Advertisement', 'updateAdvertisement')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteAdvertisement}
                  onChange={() => handleCheckboxChange('Advertisement', 'deleteAdvertisement')}
                />
              </td>
            </tr>
            {/* New entities */}
            <tr>
              <td>System User</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createSystemUser}
                  onChange={() => handleCheckboxChange('SystemUser', 'createSystemUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readSystemUser}
                  onChange={() => handleCheckboxChange('SystemUser', 'readSystemUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateSystemUser}
                  onChange={() => handleCheckboxChange('SystemUser', 'updateSystemUser')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteSystemUser}
                  onChange={() => handleCheckboxChange('SystemUser', 'deleteSystemUser')}
                />
              </td>
            </tr>

            <tr>
              <td>Customer</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createCustomer}
                  onChange={() => handleCheckboxChange('Customer', 'createCustomer')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readCustomer}
                  onChange={() => handleCheckboxChange('Customer', 'readCustomer')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateCustomer}
                  onChange={() => handleCheckboxChange('Customer', 'updateCustomer')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteCustomer}
                  onChange={() => handleCheckboxChange('Customer', 'deleteCustomer')}
                />
              </td>
            </tr>

             {/* New entities */}
             <tr>
              <td>Service</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createService}
                  onChange={() => handleCheckboxChange('Service', 'createService')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readService}
                  onChange={() => handleCheckboxChange('Service', 'readService')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateService}
                  onChange={() => handleCheckboxChange('Service', 'updateService')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteService}
                  onChange={() => handleCheckboxChange('Service', 'deleteService')}
                />
              </td>
            </tr>

            <tr>
              <td>Appointment</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createAppointment}
                  onChange={() => handleCheckboxChange('Appointment', 'createAppointment')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readAppointment}
                  onChange={() => handleCheckboxChange('Appointment', 'readAppointment')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateAppointment}
                  onChange={() => handleCheckboxChange('Appointment', 'updateAppointment')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteAppointment}
                  onChange={() => handleCheckboxChange('Appointment', 'deleteAppointment')}
                />
              </td>
            </tr>

            <tr>
              <td>Analytics</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.createAnalytics}
                  onChange={() => handleCheckboxChange('Analytics', 'createAnalytics')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.readAnalytics}
                  onChange={() => handleCheckboxChange('Analytics', 'readAnalytics')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.updateAnalytics}
                  onChange={() => handleCheckboxChange('Analytics', 'updateAnalytics')}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.permissions.deleteAnalytics}
                  onChange={() => handleCheckboxChange('Analytics', 'deleteAnalytics')}
                />
              </td>
            </tr>

            {/* Add similar rows for other entities as needed */}
          </tbody>
        </table>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default EditRole;
