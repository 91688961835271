import React, { useState, useEffect, useRef } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/services.css';
import { API_BASE_URL, API_ENDPOINTS } from '../my-api/apiConfig';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import bioVideo from '../components/publicpage/videos/biovideo.mp4'; // Import the bio video


interface Collection {
  id: number;
  collection_name: string;
  collection_description: string;
  categories: Category[];
}

interface Category {
  category_icon: string;
  id: number;
  category_name: string;
  category_description: string;
  services: Service[];
}

interface Service {
  id: number;
  service_name: string;
  price: number;
  status: string;
}

interface CartPage {
  id: number;
  user_id: number;
  service_id: number;
  status: string;
  user: {
    id: number;
    name: string;
    email: string;
  };
  spaservice: {
    service_duration: string;
    id: number;
    service_name: string;
    service_description: string;
    service_price: number;
    categorycollections: {
      id: number;
      category_name: string;
      collection: {
        id: number;
        collection_name: string;
      };
    }[];
  };
}

const Services: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');

  const [collections, setCollections] = useState<Collection[]>([]);
  const [cartPages, setCartPages] = useState<CartPage[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [originalCartPages, setOriginalCartPages] = useState<CartPage[]>([]);
  const cartTableRef = useRef<HTMLDivElement>(null);
  const [userDetails, setUserDetails] = useState<any | null>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchCollectionsAndCartData = async () => {
      try {
        if (!accessToken) {
          return;
        }
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.COLLECTIONS}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCollections(data.collections);
        } else {
          console.error('Failed to fetch collections:', response.statusText);
        }

        const cartResponse = await fetch(`${API_BASE_URL}${API_ENDPOINTS.CART}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });        

        if (cartResponse.ok) {
          const cartData = await cartResponse.json();
          if (user) {
            const userCartPages = cartData.cartpages.filter((cartPage: CartPage) => cartPage.user_id === user.id);
            setCartPages(userCartPages);
            setOriginalCartPages(userCartPages);
          }
        } else {
          console.error('Failed to fetch cart data:', cartResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCollectionsAndCartData();
  }, []);

  const handleCardClick = (collection: Collection) => {
    setSelectedCollection(collection);
    if (cartTableRef.current) {
      cartTableRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // const filteredCartPages = originalCartPages.filter((cartPage) => {
    //   return cartPage.spaservice.categorycollections.some((categoryCollection) => {
    //     return categoryCollection.collection.id === collection.id;
    //   });
    // });

    setCartPages(filteredCartPages);
  };

  const handleEditCategory = (id: number) => {
    console.log('Editing category with ID:', id);
  };

  const handleDeleteCategory = (id: number) => {
    console.log('Deleting category with ID:', id);
  };

  const handleSchedules = (cartId: number, serviceId: number) => {
    navigate(`/schedules/${serviceId}/${cartId}`);
  };

  const stripHtmlTags = (text: string) => {
    return text.replace(/<[^>]+>/g, '');
  };
// Inside your component
const filteredCartPages = cartPages.filter((cartPage) =>
  cartPage.spaservice?.categorycollections?.[0]?.category_name.toLowerCase().includes(searchQuery.toLowerCase())
);

const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

const currentCartPages = filteredCartPages.slice(indexOfFirstRecord, indexOfLastRecord);


  return (
    <Container fluid className="mt-3 service-container">
      <h1 className="text-center mb-4">Cart Page</h1>
      <section className="bio-section">
      <div className="bio-content">
      {/* <div className="video-banner">
          <video width="100%" height="100%" controls autoPlay loop muted>
            <source src={bioVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
        <div className="text-description">
          <h3 className="public-title note-title">Attention!</h3>
          <p>
            <ul>

            <li>Below we have a colection of cards labeld "spa, barber, packages and gifts"</li>
            <li>Each collection has a list all your cart services picked</li>
            <li>You are required to schedule one services at a time</li>
            <li>This helps you manage your time at the spa not to interfear with your daily routine</li>
            <li>Once a service has been scheduled it will be removed from the cart</li>
            <li>All scheduled appointments are found within your appointment accounts</li>
            </ul>
            <div className='cart-app-btns'>
              
            <Link className="view-button" to={`/appointments`}>
               View Appointments
            </Link>

            <Link className="view-button" to={`/auth-services`}>
                Add more services
            </Link>


            </div>

          </p>      
        </div>

      </div>
    </section>


      <div className="card-container-services">
        {collections.map((collection, index) => (
          <Card
            key={collection.id}
            className="custom-card-services"
            style={{ backgroundColor: `hsl(${(index * 30) % 360}, 50%, 70%)`, cursor: 'pointer' }}
            onClick={() => handleCardClick(collection)}
          >
            <Card.Body>
              <Card.Title>{collection.collection_name}</Card.Title>
              <hr className="card-line" />
              <Card.Text dangerouslySetInnerHTML={{ __html: 'click to view items' }} />
            </Card.Body>
          </Card>
        ))}
      </div>


      {selectedCollection && (
        <div ref={cartTableRef} className="datatable-container">
          <div className="d-flex justify-content-between mb-3">
            <h2>{selectedCollection.collection_name} cart</h2>
          </div>

          <div className="table-container">
            {/* Search input and button */}
            <div className="role-search-form">
              <input
                type="text"
                placeholder="Search cartPages..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="search-button" onClick={() => console.log('Search')}>
                <i className="fas fa-search"></i>
              </button>
            </div>

            {/* Records per page filter */}
            <div className="records-per-page">
              <label>Filter:</label>
              <select
                value={recordsPerPage}
                onChange={(e) => setRecordsPerPage(Number(e.target.value))}
              >
                {[5, 10, 15].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {currentCartPages.length === 0 ? (
              <p>No saved cart records.</p>
            ) : (
              <table className="cart-custom-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Category</th>
                    <th>Service</th>
                    {/* <th>Description</th> */}
                    <th>Duration</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Appointment</th>
                    {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
                      <>
                        {/* <h6 className="text-center service-title">click on each category to view services for bookings</h6> */}
                      </>
                    ) : (
                      <>
                        {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
                          <>
                            <th className='non-disp'>Action</th>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentCartPages
                    .filter((cartPage) => cartPage.status === 'pending') // Filter cartPages with status 'pending'
                    .map((cartPage, index) => (
                      <tr key={cartPage.id}>
                        <td>{index + 1}</td>
                        <td>{cartPage.spaservice?.categorycollections?.[0]?.category_name}</td>
                        <td>{cartPage.spaservice?.service_name}</td>
                        {/* <td>{stripHtmlTags(cartPage.spaservice.service_description)}</td> */}
                        <td>{cartPage.spaservice?.service_duration} minutes</td>
                        <td>ksh. {cartPage.spaservice?.service_price}</td>
                        <td>{cartPage.status}</td>
                        <td>
                          <Button
                            className="action-button"
                            variant="primary"
                            onClick={() => handleSchedules(cartPage.id, cartPage.service_id)}
                          >
                            Schedule Appointment
                          </Button>
                        </td>
                        {(user && userDetails && userDetails.roles && userDetails.roles.length === 0) ? (
                          null
                        ) : (
                          <>
                            {user && userDetails && userDetails.roles && userDetails.roles.length > 0 && (
                              <td>
                                <Button variant="info" onClick={() => handleEditCategory(cartPage.spaservice?.categorycollections?.[0]?.id)}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button variant="danger" onClick={() => handleDeleteCategory(cartPage.spaservice?.categorycollections?.[0]?.id)}>
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}

            {/* Pagination */}
            <div className="pagination">
              <ul>
                {Array.from({ length: Math.ceil(cartPages.length / recordsPerPage) }).map((_, index) => (
                  <li key={index}>
                    <button onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Services;
